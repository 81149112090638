import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { TranslateService } from '@ngx-translate/core';

import { map } from 'rxjs/operators';

import { Language } from '../classes/index';

@Injectable()
export class LanguageService {

	public languages = [];
	public languagesCodes = [];
	public currentLang = 'en';

	constructor(public translate: TranslateService,
		private afs: AngularFirestore) {
			console.log('LanguageService initialization...');

			this.listLanguages()
			.subscribe(languages => {
				this.languages = languages.sort((a, b) => {
					if (a.code > b.code) return 1;
					else if (a.code < b.code) return -1;
					else return 0;
				});
				this.languagesCodes = [];
				for(let lang of languages){
					this.languagesCodes.push(lang.code);
				}
			});
			
	}

	switchLanguage(lang){
		console.log('Selected lang is:'+lang);
		this.translate.use(lang);
		localStorage.setItem('locale',JSON.stringify(lang));
	}

	getLang(code){
		for(let lang of this.languages){
			if(lang.code == code){
				return lang;
			}
		}
		return null;
	}

	getCode(name){
		for(let lang of this.languages){
			if(lang.name == name){
				return lang.code;
			}
		}
		return null;
	}

	listLanguages(){
		return this.afs.collection('languages', ref => ref.orderBy('orderNum'))
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Language();
				object.fromJSON(data);
				return object;
			});
		}));		
	}

	sortLanguages(list){
		let sorted = [];
		for(let lang of this.languages){
		  if(list.indexOf(lang.code)>=0){
			sorted.push(lang.code);
		  }
		}
		return sorted;
	  }
}
