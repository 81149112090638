<div class="header-container">
  <app-main-menu></app-main-menu>
  <div class="logo-container">
    <!-- <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a> -->
    <a class="logo d-flex align-items-center" href="#" (click)="navigateHome()">
      <img src="assets/images/logo_coddy_horizontal.svg" alt="Coddy logo" class="w-auto mr-3" height="40">
      <span class="hidden md:block lg:block" [innerHtml]="title"></span>
      <span class="block md:hidden lg:hidden" [innerHtml]="titleMobile"></span>
    </a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <div class="hidden md:block lg:block mr-10"> Players to date: {{gameService.totalPlayerCount}}</div>
  <div class="hidden md:block lg:block mr-10"> Teams to date: {{gameService.teamCountInitiated}}</div>
  <nb-select [(selected)]="languageService.currentLang" (selectedChange)="languageService.switchLanguage($event)">
    <nb-option value="en">English</nb-option>
    <nb-option value="fr">Français</nb-option>
    <nb-option value="nl">Nederlands</nb-option>
    <nb-option value="it">Italian</nb-option>
    <nb-option value="es">Spanish</nb-option>
    <nb-option value="de">Dutch</nb-option>
    <nb-option value="po">Portuguese</nb-option>
  </nb-select>
  <ng-content select="nb-menu"></ng-content>
  <ng-content select="nb-menu"></ng-content>
  <!--<nb-actions size="small">
    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>-->
    <!--<nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> 
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
                nbContextMenuTag="user-context-menu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.displayName"
               [picture]="user?.photoURL">
      </nb-user>
    </nb-action>
  </nb-actions>-->
</div>
