<ul class="main-nav">
  <li class="dropdown">
    <nb-icon class="menu" icon="menu-outline"></nb-icon>
    <ul class="drop-nav" id="drop-nav">
      <li class="flyout" *ngIf="favoriteGames.length>0">
        <a class="title">
          <nb-icon icon="star-outline" status="warning"></nb-icon>
          <p class="title ">FAVORITE GAMES</p>
        </a>
        <ul class="flyout-nav">
          <li *ngFor="let menu of favoriteGames">
            <a routerLink={{menu.link}}>
              <nb-icon icon="{{menu.icon}}"></nb-icon>
              <p class="ml-1 dropbtn" *ngIf="menu.title" >{{menu.title[languageService.currentLang]}}</p>
            </a>
          </li>
        </ul>
      </li>
      <li class="flyout">
        <a class="title">
          <nb-icon icon="menu-outline"></nb-icon>
          <p class="title ">GAMES</p>
        </a>
        <ul class="flyout-nav">
          <li *ngFor="let menu of gamesMenu">
            <a routerLink={{menu.link}}>
              <nb-icon icon="{{menu.icon}}"></nb-icon>
              <p class="ml-1 dropbtn">{{menu.title}}</p>
            </a>
          </li>
        </ul>
      </li>
      <li class="flyout">
        <a class="title">
          <nb-icon icon="person-outline"></nb-icon>
          <p class="title ">PLAYERS</p>
        </a>
        <ul class="flyout-nav">
          <li *ngFor="let menu of playersMenu">
            <a routerLink={{menu.link}}>
              <nb-icon icon="{{menu.icon}}"></nb-icon>
              <p class="ml-1 dropbtn">{{menu.title}}</p>
            </a>
          </li>
        </ul>
      </li>
      <li class="flyout">
        <a class="title">
          <nb-icon icon="shopping-bag-outline"></nb-icon>
          <p class="title ">STATS</p>
        </a>
        <ul class="flyout-nav">
          <li *ngFor="let menu of statsMenu">
            <a routerLink={{menu.link}}>
              <nb-icon icon="{{menu.icon}}"></nb-icon>
              <p class="ml-1 dropbtn">{{menu.title}}</p>
            </a>
          </li>
        </ul>
      </li>
      <li class="flyout">
        <a class="title">
          <nb-icon icon="shopping-bag-outline"></nb-icon>
          <p class="title ">TOOLS</p>
        </a>
        <ul class="flyout-nav">
          <li>
            <a (click)="askChatGPT()">
              <nb-icon icon="bulb-outline"></nb-icon>
              <p class="ml-1 dropbtn">Ask ChatGPT</p>
            </a>
          </li>
        </ul>
      </li>
      <li class="flyout">
        <a class="title">
          <nb-icon icon="settings-outline"></nb-icon>
          <p class="title ">SETTINGS</p>
        </a>
        <ul class="flyout-nav">
          <li *ngFor="let menu of settingsMenu">
            <a routerLink="{{menu.link}}" *ngIf="menu.title !== 'Log out'">
              <nb-icon icon="{{menu.icon}}"></nb-icon>
              <p class="ml-1 dropbtn">{{menu.title}}</p>
            </a>
            <a (click)="authService.logout()" *ngIf="menu.title == 'Log out'">
              <nb-icon icon="{{menu.icon}}"></nb-icon>
              <p class="ml-1 dropbtn">{{menu.title}}</p>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>