import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class RedirectGuard implements CanActivate {

constructor(
    private router: Router
) {}

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log('IN RedirectGuard:'+route);
    console.log('IN RedirectGuard:'+route.pathFromRoot);
    console.log('IN RedirectGuard:'+route.queryParams);
    const queryParams =  route.queryParams.lang ? {
        lang: route.queryParams.lang
    } : {};

    let destPath = '';
    if(route.url[0].path == 'j/:id/:teamId'){
        destPath = '';
    }

    return this.router.navigate(['/main'],  { queryParams: queryParams })
        .then(() => true);
    }
}