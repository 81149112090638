import Serializable from './serializable';

export class Sms extends Serializable {

	id: string;

	direction: string; //in/out
	phone: string;

	message: string;

	time: number;

	sent: boolean;
	received: boolean;

	sentTime: number;
	receivedTime: number;
}
