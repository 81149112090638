import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from "@angular/core";

import { tap, map, take } from 'rxjs/operators';
import { AuthService } from './auth-service.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }

  canActivate() {
    return this.auth.userDoc
      .pipe(take(1))
      .pipe(map(authState => !!authState))
      .pipe(tap(authenticated => {

        if (!authenticated) {
            this.router.navigate(['auth/login']);
        }
        //console.log('AUTHGUARD, userInfo:'+JSON.stringify(this.auth.userInfo));
      }));
  }
  /*canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<boolean> {
    return new Promise((resolve) => {
      this.auth.credentials$.pipe(
          takeWhile(credentials => credentials === null),
      ).subscribe({
          complete: () => {
              const credentials = this.auth.userInfo;
              console.log('AUTHGUARD, authenticated:'+credentials);
              resolve(true)
          }
      })
    });
  }*/

}