import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClient} from '@angular/common/http';

import { ClipboardModule } from '@angular/cdk/clipboard';

import {
  NbButtonModule, NbTooltipModule, NbBadgeModule, NbSpinnerModule
} from '@nebular/theme';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ImageUploaderComponent } from '../components/image-uploader/image-uploader.component';
import { AudioUploaderComponent } from '../components/audio-uploader/audio-uploader.component';
import { DocumentUploaderComponent } from '../components/document-uploader/document-uploader.component';

//Pipes
import { TxtMapTranslatePipe } from '../_pipes/txtmap-translate.pipe';
import { TimeAgoPipe } from '../_pipes/timeago.pipe';
import { ObjectImagePipe } from '../_pipes/object-image.pipe';

//Translations
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    ClipboardModule,
    NbButtonModule,
    NbTooltipModule,
    NbBadgeModule,
    NgxDatatableModule,
    NbSpinnerModule
  ],
  declarations: [
    ImageUploaderComponent,
    AudioUploaderComponent,
    DocumentUploaderComponent,
    TxtMapTranslatePipe,
    TimeAgoPipe,
    ObjectImagePipe
  ],
  exports: [
    ImageUploaderComponent,
    AudioUploaderComponent,
    DocumentUploaderComponent,
    TranslateModule,
    TxtMapTranslatePipe,
    TimeAgoPipe,
    ObjectImagePipe,
    NgxDatatableModule,
    NbTooltipModule,
    NbBadgeModule,
    NbSpinnerModule
  ]
})
export class SharedModule {}
