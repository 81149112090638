import Serializable from './serializable';

export class Mission extends Serializable {

	static translatedFields = ['name','description','unlockedDescription','riddleText','riddleAnswer','externalUrlByLanguage','externalUrlLabel',
	'successTitle','successMessage','successAction','failTitle','failMessage','failAction','forkText'];
	static translatedLists = ['riddleAnswers','riddleClues','failMessageList','forkSelections','quizzQuestions'];
	static customRiddleFields = ['riddleText','riddleAnswer','riddleAnswers','riddleClues'];
	static forkFields = ['forkSelections'];
	static roleFields = ['quizzQuestions'];
	
	static structFields = ['successMissions', 'successObjects', 'failMissions'];
	static structMaps = ['dependencies', 'optionalDependencies'];
	static structLists = ['forkSelectionsStruct'];

	constructor(collectionPath) {
		super();
		this.collectionPath = collectionPath;
	}

	id: string;
	collectionPath: string;
	gameId: string;
	templateId: string;

	type: string; //REWARD/FORK

	name: {} = {};
	icon: string;
	agmIcon;
	customIcon: string;
	iconWidth: number = 40;
	iconHeight: number = 40;
	description: {} = {};
	comment: {} = {};
	orderNum: number;

	optional: boolean;

	audioPath: string;
	audioUrl: string;
	audioAutoplay: boolean;
	audioLoop: boolean;

	unlockedDescription: {} = {};
	unlockedAudioPath: string;
	unlockedAudioUrl: string;
	unlockedAudioAutoplay: boolean;
	unlockedAudioLoop: boolean;

	geographic: boolean;
	location: string;
	latitude: number;
	longitude: number;
	poiId: string;
	poiName: string;
	hidePoiIfNotComplete: boolean;
	poi;

	active: boolean;
	ghost: boolean;
	autoActivation: boolean;
	unlockDistance: number;
	ignoreDistance: boolean;

	reward: number;
	rewardMode: string;
	rewardMin: number;
	rewardMax: number;
	rewardStep: number;
	manualValidation: boolean;
	votingMode: boolean;

	riddleText: {} = {};
	riddleAnswerMode: string;
	riddleAllowedSelections: number;
	riddleAnswer: {} = {}; //value, labels, correct, points
	riddleAnswers: [];
	riddleAnswerStrictCheck: boolean;
	isBetMode: boolean;
	riddleMalus: number;
	riddleFilePath: string;
	riddleUrl: string;
	riddleThumbFileName: string;
	riddleThumbFileURL: string;
	riddlePic: {} = {};
	riddleAudioPath: string;
	riddleAudioUrl: string;
	riddleAudio: {} = {};
	autoValidation: boolean;
	canRetry: boolean;

	wrongAnswerCount: number;
	wrongAnswersAlertTriggered: boolean;
	
	riddleClues: [];

	customRiddle: boolean;
	observationRiddle: boolean;
	customRiddleProposed: boolean;
	customRiddleVerified: boolean;

	hasExternalLink: boolean;
	externalUrl: string;
	externalUrlByLanguage: {} = {};
	isExternalUrlInline: boolean;
	externalFrameHeight: string;
	externalUrlLabel: {} = {};
	customQrCode: string;

	//Forbidden zone
	zone: {
		type,
		radius,
		paths: [],
		strokeColor: '#084C61',
		strokeOpacity: '1',
		strokeWeight: '1',
		fillColor: '#084C61',
		fillOpacity: '0.2'
	}
	deactivateDeps;
    deactivateObjDeps;
    fZoneReactivationSeconds: number;
    markUsedObjects : boolean;

	actorId: string;

	successMissions = [];
	successDispatch: boolean;
	dispatchIndex: number;
	successObjects = [];
	successPopup: boolean;
	successTitle: {} = {};
	successMessage: {} = {};
	successAction: {} = {};
	successGotoNext: boolean;
	successPictureFilePath: string;
	successPictureUrl: string;
	successAudioPath: string;
	successAudioUrl: string;
	successAudioAutoplay: boolean;
	successAudioLoop: boolean;

	failMissions = [];
	failPopup: boolean;
	failTitle: {} = {};
	failMessage: {} = {};
	failMessageList: [] = [];
	failAction: {} = {};
	failGotoNext: boolean;
	failPictureFilePath: string;
	failPictureUrl: string;
	failAudioPath: string;
	failAudioUrl: string;
	failAudioAutoplay: boolean;
	failAudioLoop: boolean;
	
	forkText: {} = {};
	forkSelections: []; //value, labels, points, optional unlockedMissionId
	forkAllowedSelections: number;

	quizzQuestions: [];

	timeLimited: boolean;
	timeLimitedManual: boolean;
	timeLimitedSeconds: number;
	activeFrom: number;
	activeTo: number;

	dependencies: {} = {};
	optionalDependencies: {} = {};
	optionalDepRequiredNbr: number;
	isHiddenWhileInactive: boolean;
	isFinalMission: boolean;
	geoDependencies = [];

	playersStatus: {};
	teamReachStatus: {};
	teamStatus: {};

	complete: boolean;
	failed: boolean;
	favorite: boolean;

	activeTime: number;
	reachTime: number;
	completionTime: number;

	//text language update
	changes: any[] = []; //[{fieldName:'name', lang:'fr'}]

	teamMission;

	getIcon() {
		if(this.complete){
			if(this.failed){
				return 'assets/images/markers/mission_marker_fail.svg';
			}
			return 'assets/images/markers/mission_marker_complete.svg';
		}
		if(this.customIcon){
			return this.customIcon;
		}
		if(this.icon && this.icon !== 'mission_marker.svg'){
			return 'assets/images/markers/' + this.icon;
		}
		if(this.type == 'FZONE'){
			return 'assets/images/markers/mission_marker_forbidden_access.svg';
		}
		if(this.optional){
			return 'assets/images/markers/mission_marker_optional.svg';
		}
		return 'assets/images/markers/mission_marker.svg';
	}

	isExpired(){
		return (this.activeTo && this.activeTo<new Date().getTime());
	}

	isActive(){
		return (this.activeFrom && this.activeFrom<new Date().getTime());
	}

	getUnlockDistance() {
		//unlockDistance is encoded in feets
		if (this.unlockDistance) {
			//return MapUtils.convertFeetToMeters(this.unlockDistance);
			return this.unlockDistance;
		}
		return 20;
	}

	canForceComplete(){
		return this.type == 'REWARD' && (this.rewardMode == 'REACH' || this.rewardMode == 'RIDDLE' 
			|| this.rewardMode == 'AUTO' || this.rewardMode == 'PICTURE_PROOF' || this.rewardMode == 'VIDEO_PROOF');
	}

	isVariableReward(){
		return (this.rewardMode == 'ACTOR_RATING' || this.rewardMode == 'PICTURE_PROOF' || this.rewardMode == 'VIDEO_PROOF');
	}

	getCompletionDuration(){
		let dur = new Date(0,0,0,0,0,0);      
		if(this.completionTime>0){
			if(this.reachTime>0){
				dur.setSeconds(Math.round((this.completionTime-this.reachTime)/1000));
				return dur;
			}
			else if(this.activeTime>0){
				dur.setSeconds(Math.round((this.completionTime-this.activeTime)/1000));
				return dur;
			}
		}
		return 0;
	}

	isTranslatedList(fieldName){
		return Mission.translatedLists.indexOf(fieldName)>=0;
	}

	isTranslatedField(fieldName){
		return Mission.translatedFields.indexOf(fieldName)>=0;
	}

	hasChanges(fieldName){
		for(let change of this.changes){
			if(change.fieldName == fieldName){
				return true;
			}
		}
		return false;
	}

	getChangesDetail(){
		let txt = '';
		for(let change of this.changes){
			txt += change.fieldName;
			if(change.languages){
				txt += ' ('+change.languages+')';
			}
			txt += '\n';
		}
		return txt;
	}

	isEmpty(obj) {
		if(!obj || obj == ''){
			return true;
		}
		if(obj.blocks){
			return !(obj.blocks.length>0);
		}
		for (var key in obj) {
			if (obj.hasOwnProperty(key) && obj[key] && ('' + obj[key]).trim() !== '')
				return false;
		}
		return true;
	}
}
