import Serializable from './serializable';

export class ObjectType extends Serializable {
	id: string;

	name: string;
	labels: {} = {};

	iconUrl: string;
}

export class GameObject extends Serializable {

	constructor(collectionPath) {
		super();
		this.collectionPath = collectionPath;
	}

	id: string;
	gameId: string;
	collectionPath: string;

	objectTypeId;

	orderNum: number;
	name: string;
	labels: {} = {};
	description: {} = {};
	journalDesc: {} = {};

	iconUrl: string;
	customObject: boolean;
	playerAssigned: boolean;

	pictureUrl: string;
	filePath: string;
	pictureLastUpdate: number;
	objectPic: {} = {};

	externalUrl: string;
	externalUrlByLanguage: {} = {};
	isExternalUrlInNewWindow: boolean = false;
	externalUrlLabel: {} = {};

	isVoucher(){
		return this.objectTypeId == 'CuSPWXbmktEeTlXmU1U1';
	}
}
