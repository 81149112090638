export default class Serializable {

    fromJSON(json) {
        for (var propName in json){
            this[propName] = json[propName];
        }
        return this;
    }

    rawCopy(object){
        for (var propName in object){
            if(!this.isObject(object[propName])){
                this[propName] = object[propName];
            }
        }
    }

    isObject(obj) {
        return obj === Object(obj);
    }

}
