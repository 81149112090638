import Serializable from './serializable';

export class Role extends Serializable {

	constructor(collectionPath) {
		super();
		this.collectionPath = collectionPath;
	}

	id: string;
	gameId: string;
	collectionPath: string;

	orderNum: number;
	name: string;
	labels: {} = {};
	description: {} = {};

	pictureUrl: string;
	filePath: string;
	pictureLastUpdate: number;
	objectPic: {} = {};

	externalUrl: string;
	externalUrlByLanguage: {} = {};
	isExternalUrlInNewWindow: boolean;
	externalUrlLabel: {} = {};
}
