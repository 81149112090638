import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { environment } from '../../environments/environment';

import { map } from 'rxjs/operators';

import { Observable, BehaviorSubject } from 'rxjs';

import { Theme, Episode } from '../classes/index';

@Injectable()
export class ThemeService {

	/*themes: Theme[] = [];
	episodes: Episode[] = [];*/

	themeBase: Theme[] = [];
	episodeBase: Episode[] = [];

	private _themes: BehaviorSubject<Theme[]>;
  	public themes: Observable<Theme[]>;
	private _episodes: BehaviorSubject<Episode[]>;
	public episodes: Observable<Episode[]>;

	constructor(private afs: AngularFirestore) {
		console.log('ThemeService initialization...');
		this._themes = <BehaviorSubject<[]>>new BehaviorSubject([]);
      	this.themes = this._themes.asObservable();
		this._episodes = <BehaviorSubject<[]>>new BehaviorSubject([]);
		this.episodes = this._episodes.asObservable();

		this.listThemes()
		.subscribe(themes => {
			this.themeBase = themes.sort((a, b) => {
				if (a.name['fr'] > b.name['fr']) return 1;
				else if (a.name['fr'] < b.name['fr']) return -1;
				else return 0;
			});
			this._themes.next(this.themeBase);
		});
		this.listAllEpisodes()
		.subscribe(episodes => {
			this.episodeBase = episodes.sort((a, b) => {
				if (a.name['fr'] > b.name['fr']) return 1;
				else if (a.name['fr'] < b.name['fr']) return -1;
				else return 0;
			});
			this._episodes.next(this.episodeBase);
		});
	}

	/*listUserThemes(userId){
		return this.afs.collection('themes', ref => ref.where('userId', '==', userId))
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Theme();
				object.fromJSON(data);
				return object;
			});
		}))
		.pipe(map(themes => themes.sort((a, b) => {
			if (a.name['fr'] > b.name['fr']) return 1;
			else if (a.name['fr'] < b.name['fr']) return -1;
			else return 0;
		})));		
	}*/

	listThemes(){
		return this.afs.collection('themes')
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Theme();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	getTheme(themeUid){
        //return this.afs.collection('themes').doc(`${themeUid}`).valueChanges();
		if(this.themeBase){
			return this.themeBase.filter(function( theme ) {return theme.id == themeUid})[0];
		}
		return null;
	}

	addTheme(theme) {
		return this.afs.collection('themes').add({ ...theme });
	}

	updateTheme(themeUid, data){
		return this.afs.collection('themes').doc(`${themeUid}`).update(data);
	}

	deleteTheme(themeUid) {
		return this.afs.collection('themes').doc(`${themeUid}`).delete();
	}

	listAllEpisodes(){
		return this.afs.collectionGroup('episodes')
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Episode();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	listEpisodes(themeUid){
		return this.afs.doc(`themes/${themeUid}`).collection('episodes')
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Episode();
				object.fromJSON(data);
				return object;
			});
		}))
		.pipe(map(episodes => episodes.sort((a, b) => {
			if (a.name['fr'] > b.name['fr']) return 1;
			else if (a.name['fr'] < b.name['fr']) return -1;
			else return 0;
		})));	
	}

	getEpisode(themeUid, episodeUid){
        return this.afs.doc(`themes/${themeUid}`).collection('episodes').doc(`${episodeUid}`).valueChanges();
	}

	getLocalEpisode(episodeId){
		if(this.episodeBase){
			return this.episodeBase.filter(function( episode ) {return episode.id == episodeId})[0];
		}
		return null;
	}

	addEpisode(themeUid, episode) {
		return this.afs.doc(`themes/${themeUid}`).collection('episodes').add({ ...episode });
	}

	updateEpisode(themeUid, episodeUid, data){
		return this.afs.doc(`themes/${themeUid}`).collection('episodes').doc(`${episodeUid}`).update(data);
	}

	copyEpisode(episode, destThemeId) {
		console.log('Copy episode '+episode.id+' to theme '+destThemeId);
		let data = { ...episode };
		data.themeId = destThemeId;
		return this.afs.doc(`themes/${destThemeId}`).collection('episodes').doc(episode.id).set(data);
	}

	deleteEpisode(themeUid, episodeUid) {
		return this.afs.doc(`themes/${themeUid}`).collection('episodes').doc(`${episodeUid}`).delete();
	}
}
