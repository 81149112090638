import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { map } from 'rxjs/operators';

import { Journal } from '../classes/index';

@Injectable()
export class JournalService {

	constructor(private afs: AngularFirestore) {
		console.log('VoucherService initialization...');
	}

	listJournalTemplates(){
		return this.afs.collection('journal_templates')	//
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Journal();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	getJournalTemplate(journalId){
		return this.afs.collection(`journal_templates`).doc(journalId)
		.valueChanges()
		.pipe(map(team => {
			let obj = team as Journal;
			if(team){
				obj.id = journalId;
			}
			return obj;
		}));
	}

	getGameJournal(gameId){
		return this.afs.collection('journal_templates', ref => ref.where('gameId', '==', gameId))
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Journal();
				object.fromJSON(data);
				return object;
			});
		}));
	}
}
