import Serializable from './serializable';

export class Poi extends Serializable {

	id: string;

	userId: string;
	cityId: string;

	name: {} = {};
	description: {} = {};
	extracts: {} = {};
	sitelinks: {};

	type: string;
	types;
	kinds;

	reviewed: boolean;
	commercial: boolean;
	important: boolean;
	secondary: boolean;

	icon: string;

	origin: string;
	extid: string;
	xid:string; //opentripmap ref
	wikidata:string; //wiki poi ref
	placeId: string;
	rating:number;

	label: string;
	wikitext: string;
	thumbUrl: string;
	imageUrl: string;
	distance: number;

	location: string;
	latitude: number;
	longitude: number;

	status: string;

	detailsLoadTime: number;

	gameUsage: any[];

	lmDate: number;

	iconUrl;

	getIconUrl(){
		
		let url = '/assets/images/markers/coddy_poi_'
			+(this.secondary ? 'secondary' : (this.important ? 'important' :  (this.reviewed ? 'reviewed' : this.status)));
		/*if(this.status == 'imported' && this.rating>5){
			url += '_r5';
		}*/
		//console.log(url);
		return url+'.png';
	}

	setIconUrl(){
		this.iconUrl = this.getIconUrl();
	}

	getUsageString(lang){
		let txt = '';
		if(this.gameUsage){
			for(let usage of this.gameUsage){
				txt += usage.gameName[lang]+' ('+usage.missionName[lang]+')\r\n';
			}
		}
		return txt;
	}
	
}
