import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { take, map } from 'rxjs/operators';

import { Player } from '../classes/index';


@Injectable()
export class PlayerService {

	constructor(
		private afs: AngularFirestore
	) { }

	findPlayer(email){
		return this.afs.collectionGroup('players', ref => ref.where('email', '==', email))
		.snapshotChanges()
		.pipe(take(1))
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
				let docRef = a.payload.doc;
				const item = docRef.data() as Player;
				item.id = docRef.id;
				
				let teamRef = docRef.ref.parent.parent;
				console.log('Team id:'+teamRef.id);
				let gameRef = teamRef.parent.parent;
				console.log('Game id:'+gameRef.id);
				item.gameId = gameRef.id;
			  data.push(item);
			});
			return data;
		}))
	}

	listHighRatingPlayers(gameId, limit){
		return this.afs.collectionGroup('players', ref => ref.where("gameId", "==", gameId).where("rating", "==", 5).where("websiteComment", "!=", "").limit(limit))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				let data:any = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	get5Ratings(){
		return this.afs.collectionGroup('players', ref => ref.where("rating", "==", 5))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				let data:any = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	getPlayer(gameId, teamId, playerUid){
        return this.afs.doc(`games/${gameId}/teams/${teamId}`).collection('players').doc(`${playerUid}`).valueChanges();
	}

	addPlayer(gameId, teamId, player) {
		return this.afs.doc(`games/${gameId}/teams/${teamId}`).collection('players').add({ ...player });
	}

	setPlayer(gameId, teamId, playerUid, player) {
		return this.afs.doc(`games/${gameId}/teams/${teamId}`).collection('players').doc(`${playerUid}`).set({ ...player });
	}

	updatePlayer(gameId, teamId, playerUid, data){
		return this.afs.doc(`games/${gameId}/teams/${teamId}`).collection('players').doc(`${playerUid}`).update(data);
	}

	deletePlayer(gameId, teamId, playerUid) {
		return this.afs.doc(`games/${gameId}/teams/${teamId}`).collection('players').doc(`${playerUid}`).delete();
	}
}
