import Serializable from './serializable';

export class Character extends Serializable {

	constructor(collectionPath) {
		super();
		this.collectionPath = collectionPath;
	}

	id: string;
	gameId: string;
	collectionPath: string;

	orderNum: number;
	name: string;
	labels: {} = {};
	description: {} = {};

	pictureUrl: string;
	filePath: string;
	pictureLastUpdate: number;
	//objectPic: {} = {};

	//externalUrl: string;
	//externalUrlByLanguage: {} = {};
	//isExternalUrlInNewWindow: boolean;
	//externalUrlLabel: {} = {};

	//moods
	//{id:1234, pictureMediaId:5678, audioMediaId:9012}
	moods: [] = [];
}
