import { Injectable } from '@angular/core';

import * as cytoscape from 'cytoscape';
import popper from 'cytoscape-popper';

cytoscape.use(popper);

@Injectable()
export class DiagramService {

	constructor() {
		console.log('DiagramService initialization...');
	}

	initDiagram(params){
		return cytoscape(params);
	}
}
