
import {LatLngBounds} from "leaflet";

export class MapLocation {
    latitude: number;
    longitude: number;
    address: string;
    street: string;
    streetNr: string;
    zip: string;
    city: string;
    country: string;
    viewBounds: LatLngBounds;
}
