import Serializable from './serializable';

export class Event extends Serializable {
	id: string;
	missionId: string;
	missionName: string;

	playerId: string;
	playerName: string;

	teamId: string;
	teamName: string;
	
	type: string; //PICTURE_PROOF_SENT,RIDDLE_ANSWER_SENT,MISSION_COMPLETED,BONUS,MALUS...

	text: string;

	riddleAnswer: string;
	riddleAnswers: any[];

	forkSelections;

	time: number;

	fileName: string;
	fileURL: string;

	thumbFileName: string;
	thumbFileURL: string;

	uploadStatus: string;
	uploadProcessedDate: number;

	status: string;

	ignored: boolean;

	//BONUS/MALUS
	amount: number;

	comment: string;

	scoreable:boolean;
	scored:boolean;

	clue;

	getDisplayTimeStr(){
		return 'testtime';
	}

	isExtra(){
		return ((this.type == 'PICTURE_SENT' || this.type == 'VIDEO_SENT') && this.scored == null && !this.ignored);
	}

	isMedia(){
		return (this.type == 'PICTURE_PROOF_SENT' || this.type == 'PICTURE_SENT' || this.type == 'VIDEO_PROOF_SENT' || this.type == 'VIDEO_SENT');
	}

	isMediaUploaded(){
		return (!this.fileName || (this.uploadStatus=='finished' && this.fileURL));
	}

	isRiddleAnswerCorrect(){
		if(!this.riddleAnswers || this.riddleAnswers.length==0){
			return false;
		}
		for(let answer of this.riddleAnswers){
			if(!answer.checked != !answer.correct){
				return false;
			}
		}
		return true;
	}
}
