import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { map } from 'rxjs/operators';

import { Media } from '../classes/index';

@Injectable()
export class MediaService {

	constructor(private afs: AngularFirestore) {
	}

	listMedias(collectionPath) {
		return this.afs.collection(collectionPath).snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as Media;
				delete data.collectionPath;
				let role = new Media(collectionPath);
				role.fromJSON(data);
				role.id = a.payload.doc.id;
				return role;
			});
		}))
		.pipe(map(roles => roles.sort((a, b) => {
			if (a.orderNum > b.orderNum) return 1;
			else if (a.orderNum < b.orderNum) return -1;
			else return 0;
		})));
	}

	createMediaRef(collectionPath){
		return this.afs.collection(collectionPath).doc().ref;
	}

	setMedia(media) {
		let data = { ...media };
		console.log('Set media:'+JSON.stringify(data));
		delete data.collectionPath;
		return this.afs.collection(media.collectionPath).doc(media.id).set(data);
		/*let ref = this.afs.collection(media.collectionPath).doc().ref;
		media.id = ref.id;
		return ref.set(data);*/
	}

	updateMedia(media, data){
		data.collectionPath = firebase.firestore.FieldValue.delete();
		return this.afs.collection(media.collectionPath).doc(`${media.id}`).update({...data});
	}

	deleteMedia(media) {
		return this.afs.collection(media.collectionPath).doc(`${media.id}`).delete();
	}
}

