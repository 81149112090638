import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { TranslateService } from '@ngx-translate/core';

import { map } from 'rxjs/operators';

import { GameTemplate } from '../classes/index';
import { LanguageService } from './language.service';

@Injectable()
export class TemplateService {

	templates;

	constructor(private http: HttpClient, 
		public translate: TranslateService,
		private afs: AngularFirestore,
		public languageService: LanguageService,) {
			console.log('TemplateService initialization...');
			this.listTemplates()
			//.pipe(take(1))
			.subscribe(templates => {
				this.templates = templates.sort((a, b) => {
					if(a.name[translate.currentLang] && b.name[translate.currentLang]){
						if (a.name[translate.currentLang].toUpperCase() > b.name[translate.currentLang].toUpperCase()) return 1;
						if (a.name[translate.currentLang].toUpperCase() < b.name[translate.currentLang].toUpperCase()) return -1;
					}
					return 0;
				})
			});
	}

	getLocalTemplate(templateId){
		if(this.templates){
			return this.templates.filter(function( template ) {return template.id == templateId})[0];
		}
		return null;
	}

	listTemplates(){
		return this.afs.collection('game_templates', ref => ref.orderBy('name.'+this.languageService.currentLang, 'asc'))
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new GameTemplate();
				object.fromJSON(data);
				return object;
			});
		}));
	}
	listTemplateByEpisode(episodeId){
		return this.afs.collection('game_templates', ref => ref.where("episodeId", "==", episodeId))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				const data = a.payload.doc.data() as GameTemplate;
				data.id = a.payload.doc.id;
				return data;
			});
		}));;
	}

	getTemplate(templateUid){
		return this.afs.collection('game_templates').doc(`${templateUid}`).get()
		.pipe(map(template => {
			let obj = new GameTemplate();
			if(template && template.data()){
				obj.fromJSON(template.data());
				obj.id = templateUid;
				return obj;
			}
			return null;
		}));
	}

	addTemplate(data) {
		return this.afs.collection(`game_templates`).add({ ...data });
	}

	updateTemplate(templateUid, data){
		return this.afs.collection('game_templates').doc(`${templateUid}`).update({ ...data });
	}

	deleteTemplate(templateUid) {
		return this.afs.collection('game_templates').doc(`${templateUid}`).delete();
	}

	getDiagram(templateUid){
		return this.afs.doc(`game_templates/${templateUid}`).collection('diagrams').doc(`default`).valueChanges()
		.pipe(map(diagram => {
			let obj = diagram as any;
			return obj;
		}));
	}

	setDiagram(templateUid, diagram) {
		return this.afs.doc(`game_templates/${templateUid}`).collection('diagrams').doc(`default`).set({ ...diagram });
	}

	getTemplateChecks(){
		return this.afs.collection(`template_checks`)
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				const data = a.payload.doc.data() as any;
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	updateTemplateCheck(templateUid, data){
		return this.afs.doc(`template_checks/${templateUid}`).update(data);
	}

	setTemplateCheck(templateUid, data){
		return this.afs.doc(`template_checks/${templateUid}`).set(data);
	}

	deleteTemplateCheck(templateUid) {
		return this.afs.doc(`template_checks/${templateUid}`).delete();
	}
}
