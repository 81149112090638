import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { map } from 'rxjs/operators';

import { Role } from '../classes/index';

@Injectable()
export class RoleService {

	missionDoc: AngularFirestoreDocument<Object>;

	constructor(private afs: AngularFirestore) {
	}

	listRoles(collectionPath) {
		return this.afs.collection(collectionPath).snapshotChanges() //, ref => ref.orderBy('orderNum')
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as Role;
				delete data.collectionPath;
				let role = new Role(collectionPath);
				role.fromJSON(data);
				role.id = a.payload.doc.id;
				return role;
			});
		}))
		.pipe(map(roles => roles.sort((a, b) => {
			if (a.labels['fr'] > b.labels['fr']) return 1;
			else if (a.labels['fr'] < b.labels['fr']) return -1;
			else return 0;
		})));
	}

	getRoles(collectionPath){
		return this.afs.collection(collectionPath).get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
				let item = new Role(collectionPath);
				item.fromJSON(a.data());
				item.id = a.id;
				data.push(item);
			});
			return data;
		}));
	}

	addRole(role) {
		let data = { ...role };
		delete data.collectionPath;
		return this.afs.collection(role.collectionPath).add(data);
	}

	setRole(role) {
		let data = { ...role };
		delete data.collectionPath;
		return this.afs.collection(role.collectionPath).doc(`${role.id}`).set({ ...data });
	}

	/*async updateAllRole(templateObjects){
		var batch = this.afs.firestore.batch();
		for(let j = 0; j < templateObjects.length; j++){
			let objectsGames = templateObjects[j].changes
			for(let i = 0; i < objectsGames.length; i++){
				templateObjects[j].collectionPath = firebase.firestore.FieldValue.delete();
				batch.update(this.afs.collection(objectsGames[i].collectionPath).doc(`${objectsGames[i].id}`).ref,
					{labels:templateObjects[j].labels, description:templateObjects[j].description, pictureLastUpdate: templateObjects[j].pictureLastUpdate})				
			}
		}
		await batch.commit();	
	}*/

	updateRole(role, data){
		data.collectionPath = firebase.firestore.FieldValue.delete();
		return this.afs.collection(role.collectionPath).doc(`${role.id}`).update({...data});
	}

	deleteRole(role) {
		return this.afs.collection(role.collectionPath).doc(`${role.id}`).delete();
	}
}

