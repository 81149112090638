import Serializable from './serializable';

export class User extends Serializable {

	uid: string;
	email: string;
	displayName?: string;
	description:string;
	password:string;

	photoURL?: string;

	type:string;
	role:string;

	fcmToken: string;

	creationDate:number;
	lastUpdateDate:number;

	favoriteGames: [];
}
