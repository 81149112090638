import Serializable from './serializable';

export class Media extends Serializable {

	constructor(collectionPath) {
		super();
		this.collectionPath = collectionPath;
	}

	id: string;
	templateId: string;
	gameId: string;
	collectionPath: string;

	orderNum: number;
	name: string;

	type: string;

	description: string;
	searchTerms: string;

	originalFilePath: string;
	filePath: string;
	fileURL: string;
	thumbFilePath: string;
	thumbFileURL: string;
	lastUpdate: number;
}
