import Serializable from './serializable';

export class Episode extends Serializable {

	id: string;
	themeId: string;
	
	orderNum: number;
	code: string;

	name: {} = {};
	description: {} = {};

	callToActionStart: {} = {en:"", fr:"", nl:""}
	
	pictureUrl: string;
	thumbFileName: string;
	thumbFileURL: string;

	open: boolean;
	active: boolean;
	citySpecific: boolean;
	published: boolean;
	publishedDate: number;
	skipUserInfo: boolean;
	emailTemplate: string;
}
