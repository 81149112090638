import Serializable from './serializable';

export class Journal extends Serializable {

	id: string;

	name: string;
	
	html: string;

	title: {} = {};

	message: {} = {};
}
