export const environment = {
  production: true,
  title: 'Coddy Studio',
  menutitle: 'Coddy Studio',
  menutitleMobile: 'Studio',
  deepleKey: '06eaf05e-f6ab-5811-4467-976e9dfa43ba',
  firebase: {
    apiKey: 'AIzaSyCzC1yvO6W4Xw-C2ZUjEkjQ89kzqmxVfxo',
    authDomain: 'coddy-app.firebaseapp.com',
    databaseURL: 'https://coddy-app.firebaseio.com',
    projectId: 'coddy-app',
    storageBucket: 'coddy-app.appspot.com',
    messagingSenderId: ''
  },
  algoliasearch:{
    appId: 'H8GQ2E80OD',
    apiKey: '231efa4dc6db5d6391a05c439977f6a6'
  },
  backendUrl: 'https://us-central1-coddy-app.cloudfunctions.net/',
  webAppKey : 'b640HXSFuVd48cdqpLxJTbLxJTbBMYnPAem9QyZNiLTliMmQtN2FkO'
};
