import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { environment } from '../../environments/environment';

import { map, filter } from 'rxjs/operators';

import { Mission } from '../classes/index';

@Injectable()
export class MissionService {

	missionDoc: AngularFirestoreDocument<Mission>;

	constructor(private http: HttpClient, 
		private afs: AngularFirestore) {
	}

	listMissions(collectionPath) {
		return this.afs.collection(collectionPath, ref => ref.orderBy('orderNum','asc')).snapshotChanges() //, ref => ref.orderBy('orderNum')
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as Mission;
				delete data.collectionPath;
				let mission = new Mission(collectionPath);
				mission.fromJSON(data);
				mission.id = a.payload.doc.id;
				return mission;
			});
		}));
	}

	getMissions(collectionPath){
		return this.afs.collection(collectionPath).get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
				let item = new Mission(collectionPath);
				item.fromJSON(a.data());
				item.id = a.id;
				data.push(item);
			});
			return data;
		}));
	}

	listAllForceReachMissions(){
    	//Teams started per game
		return this.afs.collectionGroup('missions', ref => ref
			.where("scope", "==", 'team').where("forceReach", "==", true)
			.orderBy("reachTime", "desc")
			.limit(200))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('a.payload.doc:'+a.payload.doc.ref.path);
				let data:any = a.payload.doc.data();
				data.id = a.payload.doc.id;
				const path = a.payload.doc.ref.path;
				const mdata = a.payload.doc.data() as any;
				data.teamId = path.substring(path.indexOf('/'+mdata.gameId+'/teams/')+mdata.gameId.length+8,path.indexOf('/missions/'));
				return data;
			});
		}));
	}

	listOpenMissions() {		
		return this.afs.collection('open_missions')
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Mission('open_missions');
				object.fromJSON(data);
				return object;
			});
		}));
	}
	addOpenMission(openMission) {
		return this.afs.collection('open_missions').add({ ...openMission });
	}

	updateOpenMission(openMissionsUid, openMission){
		return this.afs.collection('open_missions').doc(`${openMissionsUid}`).update({...openMission});
	}

	deleteOpenMission(openMissionsUid) {
		return this.afs.collection('open_missions').doc(`${openMissionsUid}`).delete();
	}

	listAllCustomMissions(){
    	//Teams started per game
		return this.afs.collectionGroup('missions', ref => ref.where("scope", "==", 'game').where("customRiddle", "==", true))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.filter((a:any) => {
				return (a.payload.doc.ref.path.indexOf('game_templates')<0 && a.payload.doc.ref.path.indexOf('/teams/')<0)
			})
			.map(a => {
				//console.log('a.payload.doc:'+a.payload.doc.ref.path);
				let data:any = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	getMission(collectionPath, missionUid){
		return this.afs.collection(collectionPath).doc(`${missionUid}`).valueChanges()
		.pipe(map((mission:any) => {
			if(!mission){
				console.log(collectionPath+' - Mission not found');
				return null;
			}
			console.log(collectionPath+' - Found mission:'+mission);
			delete mission.collectionPath;
			let m = new Mission(collectionPath);
			m.fromJSON(mission);
			m.id = missionUid;
			return m;
		}));
	}

	addMission(mission) {
		//game.creationDate = firebase.firestore.FieldValue.serverTimestamp()
		let data = { ...mission };
		delete data.collectionPath;
		console.log('Add mission to collection:'+mission.collectionPath);
		return this.afs.collection(mission.collectionPath).add(data);
	}

	setMission(mission){
		let data = { ...mission };
		return this.afs.collection(data.collectionPath).doc(`${data.id}`).set({...data});
	}

	listMissionPictures(collectionPath, missionUid) {
		return this.afs.collection(collectionPath).doc(`${missionUid}`).collection('pictures').snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received picture:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	getMissionStats(gameId, missionId) {
		return this.afs.doc(`games/${gameId}/stats/${missionId}`)
			.snapshotChanges()
			.pipe(map(changes => {
				let data:any = changes.payload.data();
				if(data){
					data.id = changes.payload.id;
				}
				return data;
			}));
	}

	addMissionImage(mission, data){
		//console.log('Create mission image:'+JSON.stringify(data));
		return this.afs.collection(mission.collectionPath).doc(`${mission.id}`).collection('pictures').add(data);
	}

	deleteMissionImage(mission, pictureUid) {
		return this.afs.collection(mission.collectionPath).doc(`${mission.id}`).collection('pictures').doc(`${pictureUid}`).delete();
	}

	updateMissionPicture(gameId, missionId, pictureUid, data){
		return this.afs.doc(`games/${gameId}/missions/${missionId}/pictures/${pictureUid}`).update({...data});
	}

	copyMission(templateId, gameId, missionId){
		if(gameId){
			return this.http.get(environment.backendUrl+'copyMission?gameId='+gameId+'&missionId='+missionId);
		}
		else if(templateId){
			return this.http.get(environment.backendUrl+'copyMission?templateId='+templateId+'&missionId='+missionId);
		}
	}

	updateMissionFromTemplate(gameId,missionId,templateId,templateMissionId){
		//updateMissionFromTemplate?copyAll=true&gameId=BXoo5jDGOQV03GTSzcrS&missionId=6fqa4RrAgbXBidgDgGzd&templateId=gzXJTJFsjZCsO1f9ZUaj&templateMissionId=6fqa4RrAgbXBidgDgGzd
		console.log('call service: '+environment.backendUrl+'updateMissionFromTemplate?copyAll=true&gameId='+gameId+'&missionId='+missionId+'&templateId='+templateId+'&templateMissionId='+templateMissionId);
		return this.http.get(environment.backendUrl+'updateMissionFromTemplate?copyAll=true&gameId='+gameId+'&missionId='+missionId+'&templateId='+templateId+'&templateMissionId='+templateMissionId);
	}

	updateMission(mission, data){
		data.collectionPath = firebase.firestore.FieldValue.delete();
		data.changes = firebase.firestore.FieldValue.delete();
		console.log('Update mission '+mission.collectionPath+'/'+mission.id+ ' with data:'+JSON.stringify(data));
		return this.afs.collection(mission.collectionPath).doc(`${mission.id}`).update({...data});
	}

	deleteMission(mission) {
		return this.afs.collection(mission.collectionPath).doc(`${mission.id}`).delete();
	}

	listMissionWrongAnswers(collectionPath, missionUid) {
		return this.afs.collection(collectionPath).doc(`${missionUid}`).collection('wrong_answers').snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received picture:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	deleteWrongAnswer(collectionPath, missionUid, wrongAnswerId){
		return this.afs.collection(collectionPath).doc(`${missionUid}`).collection('wrong_answers').doc(wrongAnswerId).delete();
	}

	listMissionsById(missionId){
		return this.afs.collectionGroup('missions', ref => ref.where("scope", "==", 'game').where("id", "==", missionId))
		.get()
		.pipe(map(changes => {
			return changes.docs.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				const data:any = a.data();
				let m = new Mission(`games/${data.gameId}/missions`);
				m.fromJSON(data);
				m.id = a.id;
				return m;
			});
		}));
	}

	listAllMissions(){
		return this.afs.collectionGroup('missions', ref => ref.where("scope", "==", 'game'))
		.get()
		.pipe(map(changes => {
			return changes.docs.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				const data:any = a.data();
				let m = new Mission(`games/${data.gameId}/missions`);
				m.fromJSON(data);
				m.id = a.id;
				return m;
			});
		}));
	}
	/*async updateDataFromTemplate(data, missions){
		var batch = this.afs.firestore.batch();
		for(let mission of missions){
			batch.update(this.afs.collection(mission.collectionPath).doc(`${mission.id}`).ref, data);
		}
		await batch.commit();
	}*/

	async updateObservationRiddleFlag(missions){
		var batch = this.afs.firestore.batch();
		let count = 0;
		for(let mission of missions){
			console.log('update games/'+mission.gameId+'/missions/'+mission.id);
			batch.update(this.afs.collection('games/'+mission.gameId+'/missions').doc(`${mission.id}`).ref, {observationRiddle: true});
			count++;
			if(count>300){
				await batch.commit();
				count = 0;
				batch = this.afs.firestore.batch();
			}
		}
		if(count>0){
			await batch.commit();
		}
	}

	checkReferenceErrors(game, missions, objects){
		/**
		 * 	static structFields = ['successMissions', 'successObjects', 'failMissions'];
			static structMaps = ['dependencies', 'optionalDependencies'];
			static structLists = ['forkSelectionsStruct']; 
			•	forkSelections
				o	neededObjects
				o	activateMissions
				o	activateObjects
				*/

		let errors = [];
		for(let mission of missions){
			if(mission.successMissions){
				for(let successMissionId of mission.successMissions){
					if(missions.filter(m => m.id == successMissionId).length==0){
						errors.push({missionId:mission.id, type:'SUCCESS_MISSION_MISSING', id:successMissionId});
					}
				}
			}
			if(mission.failMissions){
				for(let failMissionId of mission.failMissions){
					if(missions.filter(m => m.id == failMissionId).length==0){
						errors.push({missionId:mission.id, type:'FAIL_MISSION_MISSING', id:failMissionId});
					}
				}
			}
			if(mission.successObjects){
				for(let successObjectId of mission.successObjects){
					if(objects.filter(obj => obj.id == successObjectId).length==0){
						errors.push({missionId:mission.id, type:'SUCCESS_OBJECT_MISSING', id:successObjectId});
					}
				}
			}
			if(mission.dependencies){
				for(let depId in mission.dependencies){
					if(missions.filter(m => m.id == depId).length==0){
						errors.push({missionId:mission.id, type:'DEPENDENCY_MISSING', id:depId});
					}
				}
			}
			if(mission.optionalDependencies){
				for(let depId in mission.optionalDependencies){
					if(missions.filter(m => m.id == depId).length==0){
						errors.push({missionId:mission.id, type:'OPTIONAL_DEPENDENCY_MISSING', id:depId});
					}
				}
			}
			if(mission.forkSelections){
				//console.log('forkSelections:'+JSON.stringify(mission.forkSelections));
				for(let selection of mission.forkSelections){
					if(selection.neededObjects){
						for(let objectId of selection.neededObjects){
							if(objects.filter(obj => obj.id == objectId).length==0){
								errors.push({missionId:mission.id, type:'FORK_NEEDED_OBJECT_MISSING', id:objectId});
							}
						}
					}
					if(selection.activateMissions){
						for(let missionId of selection.activateMissions){
							if(missions.filter(m => m.id == missionId).length==0){
								errors.push({missionId:mission.id, type:'FORK_ACTIVATE_MISSION_MISSING', id:missionId});
							}
						}
					}
					if(selection.activateObjects){
						for(let objectId of selection.activateObjects){
							if(objects.filter(obj => obj.id == objectId).length==0){
								errors.push({missionId:mission.id, type:'FORK_ACTIVATE_OBJECT_MISSING', id:objectId});
							}
						}
					}
				}
			}
		}
		return errors;
	}

	async listGameModifsFromTemplate(templateMissions, games, fields, languages){
		console.log('listGameModifsFromTemplate:'+fields);
		if(!fields){
			fields = [];
			Array.prototype.push.apply(fields, Mission.translatedFields);
			Array.prototype.push.apply(fields, Mission.translatedLists);
			Array.prototype.push.apply(fields, Mission.structFields);
		}
		else if(fields == 'translations'){
			fields = [];
			Array.prototype.push.apply(fields, Mission.translatedFields);
			Array.prototype.push.apply(fields, Mission.translatedLists);
		}
		for(let destGame of games){
			for(let mission of templateMissions){
				
				/*if(destGame.missions && destGame.missions.length>0 && destGame.missions[0].quizzQuestions && destGame.missions[0].quizzQuestions.length>0){
					console.log('before. game '+destGame.name['en']+', quizz sel 1 es:'+destGame.missions[0].quizzQuestions[0].labels['es']);
				}*/
				//console.log('Check mission '+mission.id+':'+mission.name['fr']);
				let destList = destGame.missions.filter(destMission => mission.id == destMission.id);
				//console.log('destList.length:'+destList.length);
				if(destList.length>0){
					//console.log('getChanges:'+destList[0]);
					let data = this.getChanges(mission, destList[0], fields, languages);
					//console.log('changes:'+JSON.stringify(data));
					/*if(destList[0].changes.length>0){
						console.log('Game:'+destGame.name['fr']+' has '+destList[0].changes.length+' changes');
					}*/
					/*if(mission.id == 'qH0MjAEfaJsFsWTnYA8B'){
						console.log(destGame.name['fr']);
						console.log('qH0MjAEfaJsFsWTnYA8B changes:'+JSON.stringify(data));
					}*/
					//console.log('getChanges-done:'+destList[0]);
					destList[0].updateData = data;
					//console.log('Mission update data:'+JSON.stringify(data));
				}
				
				/*if(destGame.missions && destGame.missions.length>0 && destGame.missions[0].quizzQuestions && destGame.missions[0].quizzQuestions.length>0){
					console.log('after. game '+destGame.name['en']+', quizz sel 1 es:'+destGame.missions[0].quizzQuestions[0].labels['es']);
				}*/
			}
		}
	}

	getChanges(mission, dest, fields, languages){
		let data: any = {};
		//console.log('getChanges:'+fields+'/'+languages);
		let changeList = [];
		//console.log('Found dest mission '+dest.id+':'+dest.name['fr']);
		for(let attr of Mission.translatedFields){
			if(this.isFieldApplicable(mission, attr) && fields.indexOf(attr)>=0 && (!mission.customRiddle || Mission.customRiddleFields.indexOf(attr)<0)){
				//console.log('check translatedFields:'+attr);
				let changes = {fieldName:attr, languages:[]};
				for(let lang of languages){
					//console.log('checkField-'+attr+'-'+lang);
					if(this.checkField(mission, dest, attr, null, lang)){
						//console.log('change detected:'+attr);
						//console.log('origin:'+JSON.stringify(mission[attr]));
						//console.log('dest:'+JSON.stringify(dest[attr]));
						changes.languages.push(lang);
						if(mission[attr].blocks){
							data[attr] = {...mission[attr]};
							//break;
						}
						else{
							//console.log('Field updated:'+mission[attr][lang]);
							data[attr+'.'+lang] = mission[attr][lang] ? mission[attr][lang].slice() : '';
						}
					}
				}
				if(changes.languages.length>0){
					changeList.push(changes);
				}
				//console.log('check translatedLists-end:'+attr);
			}
		}
		for(let attr of Mission.translatedLists){
			//console.log('Check field:'+attr);
			if(this.isFieldApplicable(mission, attr) && fields.indexOf(attr)>=0 && (!mission.customRiddle || Mission.customRiddleFields.indexOf(attr)<0)){
				//console.log('attr applicable:'+attr);
				//console.log('Found field in translatedLists');
				let changes = {fieldName:attr, languages:[]};
				for(let lang of languages){
					if(this.checkField(mission, dest, null, attr, lang)){
						//console.log('Changes detected in lang:'+lang);
						changes.languages.push(lang);
					}
				}
				if(changes.languages.length>0){
					//console.log('Changes found:'+attr);
					data[attr] = [...mission[attr]];
					changeList.push(changes);
				}
				//console.log('check translatedLists-end:'+attr);
			}
		}
		for(let attr of Mission.structFields){
			//console.log('check structField:'+attr);
			if(this.isFieldApplicable(mission, attr) && fields.indexOf(attr)>=0 && (!mission.customRiddle || Mission.customRiddleFields.indexOf(attr)<0)){
				//console.log('mission[attr]:'+mission[attr]);
				//console.log('dest[attr]:'+dest[attr]);
				//console.log('mission.successMissions:'+mission.successMissions);
				//console.log('dest.successMissions:'+dest.successMissions);
				let diff = false;
				for(let sm of mission[attr]){
					if(dest[attr].indexOf(sm)<0){
						diff=true;
					}
				}
				for(let sm of dest[attr]){
					if(mission[attr].indexOf(sm)<0){
						diff=true;
					}
				}
				if(diff){
					changeList.push({fieldName: attr});
					data[attr] = {...mission[attr]};
					//console.log(attr+' has changed');
				}
			}
			//console.log('check structField-end:'+attr);
		}
		for(let attr of Mission.structMaps){
			//console.log('check structMaps:'+attr);
			if(this.isFieldApplicable(mission, attr) && fields.indexOf(attr)>=0 && (!mission.customRiddle || Mission.customRiddleFields.indexOf(attr)<0)){
				//console.log('mission[attr]:'+mission[attr]);
				//console.log('dest[attr]:'+dest[attr]);
				let diff = false;
				for(var val in mission[attr]){
					//console.log('check val:'+val);
					if(!dest[attr][val]){
						diff=true;
					}
				}
				for(var val in dest[attr]){
					if(!mission[attr][val]){
						diff=true;
					}
				}
				if(diff){
					changeList.push({fieldName: attr});
					data[attr] = {...mission[attr]};
					//console.log(attr+' has changed');
				}
			}
		}
		dest.changes = changeList;
		return data;
	}

	isFieldApplicable(mission, field){
		//static customRiddleFields = ['riddleText','riddleAnswer','riddleAnswers','riddleClues'];
		//static forkFields = ['forkSelections'];
		if(mission.type == 'REWARD' && mission.rewardMode == 'RIDDLE' && Mission.forkFields.includes(field)){
			return false;
		}
		if(mission.type == 'FORK' && Mission.customRiddleFields.includes(field)){
			return false;
		}
		if(mission.type !== 'ROLASSIGN' && mission.type !== 'ROLREVEAL' && Mission.roleFields.includes(field)){
			return false;
		}
		return true;
	}

	checkField(origin, dest, fieldName, listName, lang){
		//console.log('Compare field '+fieldName+', lang:'+lang+', origin:'+origin+', dest:'+dest);
		//console.log('origin[fieldName]:'+origin[fieldName]+', dest[fieldName]:'+dest[fieldName]);
		if(!fieldName && !listName){
			if(this.checkTranslation(origin, dest, lang)){
				return true;
			}
		}
		else if(fieldName){
			//console.log('checkTranslation:'+fieldName);
			if(origin[fieldName] && dest[fieldName] && this.checkTranslation(origin[fieldName], dest[fieldName], lang)){
				//console.log('field should be updated:'+fieldName+'/'+lang);
				return true;
			}
			//console.log('Compare field OK');
		}
		else if(listName){
			//console.log('Check list '+listName);
			if(origin[listName] && dest[listName]){
				if(origin[listName].length != dest[listName].length){
					return true;
				}
				for(let i=0; i<origin[listName].length; i++){
					//console.log('Check '+listName+' idx='+i+', uuid='+origin[listName][i].uuid);
					if(this.checkField(origin[listName][i], dest[listName][i], 'labels', null, lang)){
						/*console.log('field should be updated:'+listName+'/'+lang+'/i:'+i);
						console.log('field should be updated template data:'+JSON.stringify(origin[listName][i]));
						console.log('field should be updated game data:'+JSON.stringify(dest[listName][i]));*/
						return true;
					}
					if(listName == 'failMessageList'){
						if(this.checkField(origin[listName][i], dest[listName][i], null, null, lang)){
							return true;
						}
					}
					if(listName == 'forkSelections'){
						if(this.checkField(origin[listName][i], dest[listName][i], 'selectedTxt', null, lang)){
							return true;
						}
						if(this.checkField(origin[listName][i], dest[listName][i], 'unselectedTxt', null, lang)){
							return true;
						}
					}
					//console.log('OK Check '+listName+' idx='+i+', uuid='+origin[listName][i].uuid);
				}
			}
		}
		return false;
	}

	checkTranslation(originObj, destObj, lang){
		//console.log('checkTranslation:'+lang);
		//console.log(originObj[lang]+' >>> '+destObj[lang]);
		//object can be a simple text map or a block list
		//console.log('originObj.blocks:'+originObj.blocks);
		//console.log('destObj.blocks:'+destObj.blocks);
		if(originObj.blocks || destObj.blocks){
			//console.log('we have blocks');
			if(!originObj.blocks || !destObj.blocks){
				//console.log('block conversion:return true');
				return true;
			}
			if(originObj.blocks.length !== destObj.blocks.length){
				//console.log('block size mismatch:return true');
				return true;
			}
			if(originObj.time !== destObj.time){
				//console.log('originObj.time:'+originObj.time);
				//console.log('destObj.time:'+destObj.time);
				//console.log('block time mismatch:return true');
				//console.log('o:'+originObj.time+', d:'+destObj.time);
				return true;
			}
			for(let i=0; i<originObj.blocks.length; i++){
				//console.log('block:'+originObj.blocks[i].id);
				if(originObj.blocks[i].id !== destObj.blocks[i].id || originObj.blocks[i].type !== destObj.blocks[i].type){
					//console.log('block el id/type mismatch:return true');
					return true;
				}
				if(originObj.blocks[i].type == 'text'){
					if(originObj.blocks[i].data[lang] !== destObj.blocks[i].data[lang]){
						//console.log('block el text mismatch:return true');
						//console.log('o:'+originObj.blocks[i].data[lang]+', d:'+destObj.blocks[i].data[lang]);
						return true;
					}
				}
				if(originObj.blocks[i].type == 'dialog'){
					if(originObj.blocks[i].data.text[lang] !== destObj.blocks[i].data.text[lang]){
						//console.log('block el dialog mismatch:return true');
						return true;
					}
					if(originObj.blocks[i].data.image && originObj.blocks[i].data.image.data.fileURL){
						if(destObj.blocks[i].data.image && destObj.blocks[i].data.image.data.fileURL && originObj.blocks[i].data.image.data.fileURL !== destObj.blocks[i].data.image.data.fileURL){
							//console.log('block el image mismatch:return true');
							return true;
						}
					}
					if(destObj.blocks[i].data.image && destObj.blocks[i].data.image.data[lang] && originObj.blocks[i].data.image.data[lang] !== destObj.blocks[i].data.image.data[lang]){
						//console.log('block el image 2 mismatch:return true');
						return true;
					}
				}
				if(originObj.blocks[i].type == 'media'){
					if(originObj.blocks[i].data.fileURL !== destObj.blocks[i].data.fileURL){
						//console.log('block media mismatch:return true');
						//console.log('origin:'+originObj.blocks[i].data.fileURL);
						//console.log('dest:'+destObj.blocks[i].data.fileURL);
						return true;
					}
					/*if(originObj.blocks[i].data[lang] !== destObj.blocks[i].data[lang]){
						console.log('block media mismatch2:return true');
						console.log('origin:'+JSON.stringify(originObj.blocks[i].data[lang]));
						console.log('dest:'+JSON.stringify(destObj.blocks[i].data[lang]));
						return true;
					}*/
				}
				/*
				
				console.log('block el mismatch:return true');
				console.log('block a:'+JSON.stringify(originObj.blocks[i]));
				console.log('block b:'+JSON.stringify(destObj.blocks[i]));
				if(JSON.stringify(originObj.blocks[i]) !== JSON.stringify(destObj.blocks[i])){
					return true;
				}*/
			}
		}
		else{
			if(originObj[lang] !== destObj[lang]){
				//console.log('field should be updated');
				return true;
			}
		}
		return false;
	}

	async applyChanges(templateMission, games, id){
		var batch = this.afs.firestore.batch();
		for(let game of games){
			console.log('Apply changes to game '+game.name['fr']);
			let mission = game.missions.find(mission => mission.id == id);
			if(mission){
				console.log('Update mission '+mission.name['fr']);
				if(mission.updateData){
					console.log('Update mission '+mission.collectionPath+'/'+mission.id+' with data:'+JSON.stringify(mission.updateData));
					batch.update(this.afs.collection(mission.collectionPath).doc(`${mission.id}`).ref, mission.updateData);
				}
				else{
					console.log('Mission has changes but no updateData');
				}
			}
			else{
				console.log('Mission not found in game, create it');
				let gameMission = {...templateMission};
				gameMission.collectionPath = `games/${game.id}/missions`;
				gameMission.gameId = game.id;
				batch.set(this.afs.collection(gameMission.collectionPath).doc(`${gameMission.id}`).ref, gameMission);
			}			
		}
		await batch.commit();
	}

	/*async copyTranslations(fromTemplateId, toGameId){
		const languages = ['en','es','it','fr','nl'];
		let originalMissions = await this.listMissions(`game_templates/${fromTemplateId}/missions`).pipe(first()).toPromise();
		let destinationMissions = await this.listMissions(`games/${toGameId}/missions`).pipe(first()).toPromise();
		console.log();
		for(let mission of originalMissions){
			console.log('Check mission '+mission.id+':'+mission.name['fr']);
			let dest = destinationMissions.filter(destMission => mission.id == destMission.id)[0];
			if(dest){
				let data: any = {};
				console.log('Found dest mission '+dest.id+':'+dest.name['fr']);
				for(let attr of Mission.translatedFields){
					if(this.checkField(mission, dest, attr, null, languages)){
						data[attr] = dest[attr];
					}
				}
				for(let attr of Mission.translatedLists){
					if(this.checkField(mission, dest, null, attr, languages)){
						data[attr] = dest[attr];
					}
				}
				console.log('Mission update data:'+JSON.stringify(data));
			}
		}
		for(let mission of originalMissions){
			//console.log('Check mission '+mission.id+':'+mission.name['fr']);
			let dest = destinationMissions.filter(destMission => mission.id == destMission.id)[0];
			if(!dest){
				console.log('Mission does not exist in dest game:'+mission.id+', name:'+mission.name['fr']);
			}
		}
		for(let mission of destinationMissions){
			//console.log('Check mission '+mission.id+':'+mission.name['fr']);
			let orig = originalMissions.filter(origMission => mission.id == origMission.id)[0];
			if(!orig){
				console.log('Dest game mission should be deleted:'+mission.id+', name:'+mission.name['fr']);
			}
		}
		var batch = this.afs.firestore.batch();
		for(let mission of missions){
			batch.update(this.afs.collection(mission.collectionPath).doc(`${mission.id}`).ref, data);
		}
		await batch.commit();
	}*/

	private handleError(error: any): Promise<any> {
		console.error('An error occurred', error); // for demo purposes only
		return Promise.reject(error.message || error);
    }
}

