import Serializable from './serializable';

export class Player extends Serializable {
	id: string;
	gameId: string;
	teamId: string; //optional

	userId: string;

	name: string;
	lastName: string;
	firstName: string;
	email: string;
	
	description: string;
	password: string;

	location;
	lastLocationDate: number;
	score: number;

	teamIcon: string;

	rating: number;
}
