import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import firebase from 'firebase/compat/app'

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { User } from '../classes/index';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthService {

  public userId;
  public user: Observable<firebase.User>;
  public userDetails: firebase.User = null;

  public userDoc;
  public userInfo: User;

	private _credentials: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public readonly credentials$: Observable<any> = this._credentials.asObservable();

  constructor(private _firebaseAuth: AngularFireAuth, private router: Router, private _firestore: AngularFirestore) {
    this.user = _firebaseAuth.authState;

		this.userDoc = this.user
    .pipe(switchMap(user => {
      console.log('Logged in user detected:'+JSON.stringify(user));
			this._credentials.next(null);
			if (user) {
				this.userId = user.uid;
				return this._firestore.doc<User>(`users/${user.uid}`).valueChanges();
			} else {
				this.userId = null;
				return of(null);
			}
    }));
		this.userDoc.subscribe(user => {
			this.userInfo = user;
      this._credentials.next(user);
		});
	
    /*this.user.subscribe(
      (user) => {
        if (user) {
          this.userId = user.uid;
          this.userDetails = user;
          console.log('Authenticated user:'+JSON.stringify(this.userDetails));
          this.listenToUser();
          this.updateUserData(user);
        }
        else {
          this.userDetails = null;
        }
      }
    );*/
  }

  /*listenToUser(){
    if(this.userDoc){
      this.userDoc.unsubscribe();
    }
    console.log('listenToUser:'+this.userId);
    if(this.userId){
      this.userDoc = this._firestore.doc<User>(`users/${this.userId}`).valueChanges();
      this.userDoc.subscribe(userData => {
        this.userInfo = userData;
        console.log('got coddy user:'+this.userInfo);
      });
    }
  }*/
  getUser(){
    return this._firestore.doc<User>(`users/${this.userId}`).valueChanges();
  }
  async register(email, password, fullName, company) {
    return this._firebaseAuth.createUserWithEmailAndPassword(email, password)
      .then(
        async (afUser: firebase.auth.UserCredential) => {
          //await this.updateUserData(afUser.user);
          afUser.user.updateProfile({displayName: fullName});
          //this._firestore.firestore.collection("users").doc(afUser.user.uid).set({ company: company });
          
          this.sendEmailVerification();
        });
  }

  /*updateUserData(afUser){
    this._firestore.firestore.collection("users").doc(afUser.uid).set(
      {
        uid: afUser.uid,
        displayName: afUser.displayName || '',
        email: afUser.email || '',
        photoURL: afUser.photoURL || ''
      }, { merge: true }
    );
  }*/

  sendEmailVerification() {
    this._firebaseAuth.authState.subscribe(user => {
      console.log('Send verification email to user:'+JSON.stringify(user));
        user.sendEmailVerification()
        .then(() => {
          console.log('email sent');
        })
      });
  }

	updateUserData(favoriteGames) {
		const userRef: AngularFirestoreDocument<any> = this._firestore.doc(`users/${this.userId}`);
		return this._firestore.doc(`users/${this.userId}`).update({favoriteGames: favoriteGames});
	}

  requestPass(email) {
    return this._firebaseAuth.sendPasswordResetEmail(email);
  }

  confirmPasswordReset(code, newPassword) { // param: oobCode=<code>
    return this._firebaseAuth.confirmPasswordReset(code, newPassword);
  }

  /*verifyPasswordResetCode(code){
    return this._firebaseAuth.auth.verifyPasswordResetCode(code);
  }*/

  signInWithEmail(email, password) {
    return this._firebaseAuth.signInWithEmailAndPassword(email, password);
  }
  signInWithTwitter() {
    return this._firebaseAuth.signInWithPopup(
      new firebase.auth.TwitterAuthProvider()
    )
  }
  signInWithFacebook() {
    return this._firebaseAuth.signInWithPopup(
      new firebase.auth.FacebookAuthProvider()
    )
  }
  signInWithGoogle() {
    return this._firebaseAuth.signInWithPopup(
      new firebase.auth.GoogleAuthProvider()
    )
  }

  isLoggedIn() {
    if (this.userDetails == null) {
      return false;
    } else {
      return true;
    }
  }
  logout() {
    this._firebaseAuth.signOut()
      .then((res) => this.router.navigate(['/auth/login']));
  }
}