import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from '../auth/auth-service.service';

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(private router: Router,
        private authService: AuthService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
    
        const expectedRole = next.data.expectedRole;
        const redirect = next.data.redirect;
        console.log('ROLEGUARD-redirect:'+next.data.redirect);

        let user = this.authService.userInfo;
        if(user){
            //console.log('User is connected. What is user right?'+user.role);
            if(expectedRole == user.role){
                return true;
            }
            else if(redirect){
                console.log('Redirect user to:'+redirect);
                this.router.navigate([redirect]);
            }
        }
        return false;
    }

    isAdmin(){
        let user = this.authService.userInfo;
        if(user){
            //console.log('User is connected. What is user right?'+user.role);
            if(user.role && user.role.toUpperCase() == 'ADMIN'){
                return true;
            }
        }
        return false;
    }

    hasOperationalRight(){
        let user = this.authService.userInfo;
        if(user){
            //console.log('User is connected. What is user right?'+user.role);
            if(user.role.toUpperCase() == 'ADMIN' || user.role.toUpperCase() == 'OPERATOR'){
                return true;
            }
        }
        return false;
    }
}
@Injectable()
export class MobileGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate() {
        if (window.innerWidth >= 768) {
            this.router.navigate(['/pages/home']);
            return false;
        }

        return true;
    }
}

@Injectable()
export class DesktopGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate() {
        if (window.innerWidth < 768) {
            this.router.navigate(['/pages/home/m/']);
            return false;
        }
        
        return true;
    }
}