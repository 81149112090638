import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';


@Injectable()
export class AIService {

	constructor(private http: HttpClient) {
	}

	askChatGPT(engine, prompt, maxTokens, contentUrl = null){
		let url = environment.backendUrl+'askChatGPT';
		return this.http.post(url, {
			model: engine, 
			prompt: prompt, 
			maxTokens: maxTokens, 
			contentUrl: contentUrl
		}, {responseType: 'text'});
	}
}
