import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GameObject, ObjectType } from '../classes/index';

@Injectable()
export class ObjectService {

	missionDoc: AngularFirestoreDocument<Object>;

	constructor(private afs: AngularFirestore) {
	}

	listObjectTypes() {
		return this.afs.collection('object_types', ref => ref.orderBy('orderNum')).snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as ObjectType;
				let object = new ObjectType();
				object.fromJSON(data);
				object.id = a.payload.doc.id;
				return object;
			});
		}));
	}

	listObjectsByObjectTypes(collectionPath, objectTypeId) {
		return this.afs.collection(collectionPath, ref => ref.where("objectTypeId", "==", objectTypeId)).snapshotChanges() //, ref => ref.orderBy('orderNum')
		.pipe(map(changes => {
			return changes.map(a => {
				const data = a.payload.doc.data() as GameObject;
				delete data.collectionPath;
				let object = new GameObject(collectionPath);
				object.fromJSON(data);
				object.id = a.payload.doc.id;
				return object;
			});
		}))
		.pipe(map(objects => objects.sort((a, b) => {
			if (a.labels['fr'] > b.labels['fr']) return 1;
			else if (a.labels['fr'] < b.labels['fr']) return -1;
			else return 0;
		})));
	}

	listAllGameObjectsByObjectTypes(objectTypeId) {
		return this.afs.collectionGroup('objects', ref => ref.where("scope", "==", 'game').where("objectTypeId", "==", objectTypeId)).snapshotChanges() //, ref => ref.orderBy('orderNum')
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
				let object:any = a.payload.doc.data() as GameObject;
				let item = new GameObject(`games/${object.gameId}/objects`);
				item.fromJSON(a.payload.doc.data());
				item.id = a.payload.doc.id;
				data.push(item);
			});
			return data;
		}));
	}

	listObjects(collectionPath) {
		return this.afs.collection(collectionPath).snapshotChanges() //, ref => ref.orderBy('orderNum')
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as GameObject;
				delete data.collectionPath;
				let object = new GameObject(collectionPath);
				object.fromJSON(data);
				object.id = a.payload.doc.id;
				return object;
			});
		}))
		.pipe(map(objects => objects.sort((a, b) => {
			if (a.labels['fr'] > b.labels['fr']) return 1;
			else if (a.labels['fr'] < b.labels['fr']) return -1;
			else return 0;
		})));
	}

	getObjects(collectionPath){
		return this.afs.collection(collectionPath, ref => ref.orderBy('orderNum', 'asc')).get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
				let item = new GameObject(collectionPath);
				item.fromJSON(a.data());
				item.id = a.id;
				data.push(item);
			});
			return data;
		}));
	}

	addObject(object) {
		let data = { ...object };
		delete data.collectionPath;
		return this.afs.collection(object.collectionPath).add(data);
	}

	setObject(object) {
		let data = { ...object };
		delete data.collectionPath;
		return this.afs.collection(object.collectionPath).doc(`${object.id}`).set({ ...data });
	}

	async updateAllObject(templateObjects){
		var batch = this.afs.firestore.batch();
		for(let j = 0; j < templateObjects.length; j++){
			let objectsGames = templateObjects[j].changes
			for(let i = 0; i < objectsGames.length; i++){
				templateObjects[j].collectionPath = firebase.firestore.FieldValue.delete();
				batch.update(this.afs.collection(objectsGames[i].collectionPath).doc(`${objectsGames[i].id}`).ref,
					{labels:templateObjects[j].labels, description:templateObjects[j].description, pictureLastUpdate: templateObjects[j].pictureLastUpdate})				
			}
		}
		await batch.commit();	
	}

	updateObject(object, data){
		data.collectionPath = firebase.firestore.FieldValue.delete();
		console.log('Update object:'+object.collectionPath+'/'+object.id);
		console.log('DATA:'+JSON.stringify(data));
		return this.afs.collection(object.collectionPath).doc(`${object.id}`).update({...data});
	}

	deleteObject(object) {
		return this.afs.collection(object.collectionPath).doc(`${object.id}`).delete();
	}

	initPlayObjects(gameId, playId) {
		return Observable.create(async (observer) => {
			console.log('get objects from game:'+gameId);
			this.afs.doc(`games/${gameId}`).collection('objects') //, ref => ref.orderBy('orderNum')
			//.snapshotChanges()
			.get()
			.pipe(
				map(objects => {
				  const data = [];
				  objects.forEach(a => {
					const item = a.data() as GameObject;
					item.id = a.id;
					data.push(item);
				  });
				  return data;
				})
			)
			.subscribe(async objects => {
				//console.log('Found missions:'+JSON.stringify(missions));
				for(let object of objects){
					
					console.log('Init play object:'+object.name);
					await this.addPlayObject(playId,object.id,object);
				}
				observer.next();
			});
		});
	}

	addPlayObject(playId, objectId, data) {
		//game.creationDate = firebase.firestore.FieldValue.serverTimestamp()
		return this.afs.doc(`plays/${playId}/objects/${objectId}`).set({ ...data });
	}
}

