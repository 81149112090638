import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { map } from 'rxjs/operators';

import { combineLatest } from 'rxjs';

import { Voucher } from '../classes/index';
import { saveAs} from 'file-saver';
import { environment } from '../../environments/environment';
import algoliasearch from 'algoliasearch';


@Injectable()
export class VoucherService {

	searchClient = algoliasearch(
		environment.algoliasearch.appId,
		environment.algoliasearch.apiKey
	);
    indexVouchers = this.searchClient.initIndex('vouchers');
	constructor(private afs: AngularFirestore) {
		console.log('VoucherService initialization...');
	}

	downloadJournal(url){
		return new Promise(resolve => {
			saveAs(url, 'Journal.png')
			resolve(true)
		})
	}

	listVouchers(){
		return this.afs.collection('vouchers')	//, ref => ref.orderBy('customer')
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Voucher();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	getVouchersByIds(voucherIds){
		let refList = [];
		for(let id of voucherIds){
			refList.push(this.afs.collection("vouchers").doc(id)
			.valueChanges()
			.pipe(map(voucher => {
				let obj = voucher as Voucher;
				if(voucher){
					obj.id = id;
				}
				return obj;
			})));
		}

		return combineLatest(refList);
	}

	getVoucher(voucherId){
		return this.afs.collection('vouchers').doc(`${voucherId}`).valueChanges()
		.pipe(map(voucher => {
			let obj = voucher as Voucher;
			if(voucher){
				obj.id = voucherId;
			}
			return obj;
		}));
	}

	addVoucher(voucher) {
		return this.afs.collection('vouchers').add({ ...voucher });
	}

	async addMultipleVouchers(vouchers){
		var batch = this.afs.firestore.batch();
		let count = 0;
		for (let i = 0; i < vouchers.length; i++) {
			let ref = this.afs.collection('vouchers').doc().ref;
			batch.set(ref, { ...vouchers[i] });
			count++
		}
		console.log('voucherNbr :'+count);
		if(count > 0) await batch.commit();
		console.log('batchAdd Vouchers finished');
	}

	deleteVoucher(voucherId) {
		return this.afs.collection('vouchers').doc(`${voucherId}`).delete();
	}

	async setLmDate(vouchers){
		var batch = this.afs.firestore.batch();
		let count = 0;
		for(let i = 0; i < vouchers.length; i++){
			console.log('Update voucher:'+vouchers[i].id);
			batch.update(this.afs.collection('vouchers').doc(`${vouchers[i].id}`).ref,
					{lastUpdateDate: new Date().getTime()});
			count++;
			if(count>=100){
				await batch.commit();
				batch = this.afs.firestore.batch();
			}
		}
		if(count>0){
			await batch.commit();
		}
	}

	/*

	updateTheme(themeUid, data){
		return this.afs.collection('themes').doc(`${themeUid}`).update(data);
	}*/

	async isUniqueIdValid(id){
		const ref = this.afs.collection('vouchers', ref => ref.where('code', '==', id.toUpperCase()));
		let teams = await ref.get().toPromise();
		if(teams.docs.length>0){
			console.log('Found teams with id:'+teams.docs.length);
			return false;
		}
		return true;
	}
}
