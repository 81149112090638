import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { AIService, LanguageService, PoiService } from '../../_services/index';

import { from, concatMap } from 'rxjs';

import translate from "translate";

import { environment } from './../../../environments/environment';

//const prompt_wiki = `Summarize the text I give you in 5 or 6 facts, each on their own line starting with a different suitable emoji. None of the facts should mention the location of the point of interest. Your response should be in {{SELECTED_LANGUAGE}}. Use the following content: {{CONTENT}}`;

@Component({
  selector: 'ngx-chatgpt-modal',
  templateUrl: 'chatgpt-modal.component.html',
  styleUrls: ['chatgpt-modal.component.scss'],
})
export class ChatGPTModalComponent {

  @Input() prompt;
  @Input() promptType;
  @Input() contentUrl;
  @Input() lang = 'English';

  prompt_wiki;
  engine = 'gpt-4-0613';
  translate = false;

  answer;
  translatedAnswer;

  sending;
  translating;

  constructor(protected ref: NbDialogRef<ChatGPTModalComponent>,
    public aiService: AIService,
    private poiService: PoiService,
    public languageService: LanguageService) {}

  ngOnInit(): void {
    /*if(this.promptType == 'poi_wiki'){
      this.prompt = prompt_wiki.replace('{{WIKIURL}}',this.wikiUrl);
    }*/
    //this.prompt = prompt_wiki;
    this.prompt = `Summarize the text hereafter in 5 or 6 facts, each on their own line starting with a different suitable emoji. None of the facts should mention the location of the point of interest. {{TRANSLATE_INSTR}} The text to translate is: {{CONTENT}}`;
  }

  send(){
    delete this.answer;
    if(this.prompt.length>0){
      this.sending = true;
      let finalPrompt = this.prompt;
      finalPrompt = finalPrompt.replace('{{TRANSLATE_INSTR}}', `Your response must be in ${this.lang}.`);
      console.log('Final prompt:'+finalPrompt);
      this.aiService.askChatGPT(this.engine, finalPrompt, null, this.contentUrl)
      .subscribe((res: any) => {
        console.log('Found res:'+JSON.stringify(res));

        this.answer = res;
        this.sending = false;
      });
    }
  }

  translateRes(){
    this.translating = true;
    let prompt = `Translate the text hereafter as a whole in ${this.languageService.languages.map(language => language.code).join(', ')} in the exact same order. Separate each translation by --translate-- The text is: "${this.answer}"`;
    console.log('Translate prompt:'+prompt);
    this.aiService.askChatGPT(this.engine, prompt, null, null)
    .subscribe((res: any) => {
      console.log('Found res:'+JSON.stringify(res));

      let translations = res.split('--translate--');
      console.log('found translations:'+translations.length);
      this.translatedAnswer = {};
      for(let i=0; i<this.languageService.languages.length; i++){
        this.translatedAnswer[this.languageService.languages[i].code] = translations[i].trim();
      }
      this.translating = false;
    });
    
  }

  async translateDeepl(){
    this.translating = true;
    translate.engine = "deepl";
    translate.key = environment.deepleKey;
    this.translatedAnswer = {};

    for(let language of this.languageService.languages){
      if(this.lang == language.code){
        this.translatedAnswer[language.code] = this.answer;
      }
      else{
        try{
          const translatedTxt = await Promise.race([
            translate(this.answer, { from:this.lang, to: language.code }),
            new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 4000))
          ]);
          if(translatedTxt){
            this.translatedAnswer[language.code] = translatedTxt.split(/\r?\n/).map(line => '<p>'+line+'</p>').join('');
          }
        }
        catch(error){
          console.log('error translating to '+language.code,error);
        }
      }
    }
    this.translating = false;
  }

  validateAnswer(useTranslation){
    if(useTranslation){
      this.ref.close({
        lang: 'all',
        text: this.translatedAnswer
      });
    }
    else{
      this.ref.close({
        lang: this.languageService.getCode(this.lang),
        text: this.answer.split(/\r?\n/).map(line => '<p>'+line+'</p>').join('')
      });
    }
  }

  cancel() {
    this.ref.close();
  }
}
