import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { map } from 'rxjs/operators';

import { Sms } from '../classes/index';

@Injectable()
export class SmsService {

	constructor(private afs: AngularFirestore) {
		console.log('VoucherService initialization...');
	}

	listSms(teamId){
		return this.afs.collection('sms', ref => ref.where('teamId', '==', teamId).orderBy('time','desc'))	//
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Sms();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	addSms(sms) {
		return this.afs.collection('sms').add({ ...sms });
	}

	deleteSms(smsId) {
		return this.afs.collection('sms').doc(`${smsId}`).delete();
	}

	updateSms(smsId, data){
		return this.afs.collection('sms').doc(`${smsId}`).update(data);
	}
}
