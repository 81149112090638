import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';

import { map } from 'rxjs/operators';

import { Ticket } from '../classes/index';

@Injectable()
export class TicketService {

	constructor(private afs: AngularFirestore) {
		console.log('VoucherService initialization...');
	}

	listTickets(){
		return this.afs.collection('tickets')	//, ref => ref.orderBy('customer')
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Ticket();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	getTicket(ticketId){
		return this.afs.collection('tickets').doc(`${ticketId}`).valueChanges()
		.pipe(map(ticket => {
			let obj = ticket as Ticket;
			if(ticket){
				obj.id = ticketId;
			}
			return obj;
		}));
	}

	listTicketsByTime(dateDebut, dateFin){
		var start = moment(dateDebut).startOf('day').valueOf();
		var end = moment(dateFin).endOf('day').valueOf();
		return this.afs.collection('tickets', ref => ref.where("orderTime", ">=", start).where("orderTime", "<=", end).orderBy('orderTime','desc'))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as Ticket;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}))
	}

	retry(ticketId, data){
		return this.afs.collection('tickets').doc(`${ticketId}`).update({...data});
	}

	findTicket(email){
		return this.afs.collection('tickets', ref => ref.where('email', '==', email))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as Ticket;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}))
	}
	findTicketByOtaBookingRef(bookingRef){
		return this.afs.collection('tickets', ref => ref.where('bookingRef', '==', bookingRef))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as Ticket;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}))
	}
	findTicketByCoddyRefNumber(refNumber){
		return this.afs.collection('tickets', ref => ref.where('refNumber', '==', refNumber))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as Ticket;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}))
	}
	addTicket(ticket) {
		return this.afs.collection('tickets').add({ ...ticket });
	}

	deleteVoucher(ticketId) {
		return this.afs.collection('tickets').doc(`${ticketId}`).delete();
	}

	getEmailTemplate(name){
		return this.afs.collection('email_templates').doc(name).valueChanges();
	}
}
