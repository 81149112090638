import Serializable from './serializable';

export class Voucher extends Serializable {

	id: string;

	customer: string;
	description: string;
	
	type: string;
	code: string;
	active: boolean;

	themeId: string;
	gameId: string;
	playerCount: number;

	teamId: number;
	groupId: number;
}
