import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { map } from 'rxjs/operators';

import { Subscriber } from '../classes/index';

@Injectable()
export class SubscriberService {

	constructor(private afs: AngularFirestore) {
		console.log('SubscriberService initialization...');
	}

	findSubscriber(email){
		return this.afs.collection('subscribers', ref => ref.where('email', '==', email))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as Subscriber;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}))
	}

	deleteSubscriber(id) {
		return this.afs.collection('subscribers').doc(`${id}`).delete();
	}
}
