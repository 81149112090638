import Serializable from './serializable';

export class Theme extends Serializable {

	id: string;
	
	orderNum: number;
	code: string;

	name: {} = {};
	description: {} = {};
	
	pictureUrl: string;
	thumbFileName: string;
	thumbFileURL: string;

	open: boolean;
	active: boolean;
	published: boolean;
	publishedDate: number;
}
