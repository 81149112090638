import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { User } from '../classes/index';

import { environment } from '../../environments/environment';

import { of } from 'rxjs';

import { map } from 'rxjs/operators';

@Injectable()
export class UserService {

	usersCollection: AngularFirestoreCollection<User>; //Firestore collection
	users = [];

	constructor(private http: HttpClient,
		public afAuth: AngularFireAuth,
		private afs: AngularFirestore) {

		console.log('in UserService');
		console.log('UserService initialization...');

		//const starsRef = this.afs.collection('stars', ref => ref.where('userId', '==', userId) );
		this.usersCollection = this.afs.collection('users', ref => ref.where('type', '==', 'APP_USER'));

		this.listUsers()
		.subscribe(users => {
			this.users = users.sort((a, b) => {
				if (a.displayName.toUpperCase() > b.displayName.toUpperCase()) return 1;
				if (a.displayName.toUpperCase() < b.displayName.toUpperCase()) return -1;
				return 0;
			})
		})
	}

	listUsers(){
		return this.usersCollection
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new User();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	findUser(email){
		return this.afs.collection('users', ref => ref.where('email', '==', email))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as User;
			  data.push(item);
			});
			return data;
		}))
	}

	getUser(userUid){
        return this.afs.doc(`users/${userUid}`).valueChanges();
	}

	createUser(user) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Accept', 'application/json');

		return this.http.post(environment.backendUrl+'createUser', user, {headers: headers});		
	}

	/*addUser(user, password) {
		return Observable.create((observer) => {

			this.afAuth
			.auth
			.createUserWithEmailAndPassword(user.email, password)
			.then(value => {
				console.log('Firebase user successfully created:'+JSON.stringify(value));
				//this.usersCollection.add({ ...user })
				user.uid = value.user.uid;
				let data = {
					email:user.email, 
					displayName:user.displayName, 
					description:user.description, 
					type:user.type,
					role:user.role,
					creationDate:user.creationDate,
					lastUpdateDate:user.lastUpdateDate
				};
				this.afs.doc(`users/${value.user.uid}`)
      			.set(data)
				.then(res => {
					console.log('User successfully created:'+JSON.stringify(res));
					observer.next(res);
				})
				.catch(err => {
					console.log('Something went wrong:',err.message);
					throw Observable.throw('Error creating coddy user:'+err);
				});
			})
			.catch(err => {
				console.log('Something went wrong creating firebase user:',err.message);
				throw Observable.throw('Error creating firebase user:'+err);
			}); 
		});
	}*/

	updateUser(userUid, data){
		return this.afs.doc(`users/${userUid}`).update(data);
	}

	updateUserPassword(userUid, password){
		//return this.afs.doc(`users/${userUid}`).update(data);
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Accept', 'application/json');

		let data = {password:password}

		return this.http.post(environment.backendUrl+'updateUser?userUid='+userUid, data, {headers: headers});
	}

	deleteUser(userUid) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		headers.append('Accept', 'application/json');

		console.log('Delete user:'+userUid);
		return this.http.post(environment.backendUrl+'deleteUser', {uid:userUid}, {headers: headers});		
	}

	async deleteUserAccount(user){
		await this.afs.doc(`account_deletion_requests/${user.uid}`).set({
            email: user.email,
            time: new Date().getTime(),
            optout: true,
            deleteTakenPictures: true
        })
	}

	/*updateUserData(user) {
		// Sets user data to firestore on login
	
		const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
	
		const data = {
		  uid: user.uid,
		  email: user.email,
		  displayName: user.displayName,
		  photoURL: user.photoURL
		}
	
		return userRef.set(data, { merge: true })
	}*/

}