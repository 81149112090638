import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { Actor } from '../classes/index';

import { map } from 'rxjs/operators';

@Injectable()
export class ActorService {

	constructor(private http: HttpClient, 
		private afs: AngularFirestore) {
	}

	listActors(gameId){
		return this.afs.doc(`games/${gameId}`).collection('actors').snapshotChanges()
    	.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received actor:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as Actor;
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	listUserActors(gameId, userId){
		return this.afs.doc(`games/${gameId}`).collection('actors', ref => ref.where('userId', '==', userId)).snapshotChanges()
    	.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received actor:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as Actor;
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	getActor(gameId, actorUid){
        return this.afs.doc(`games/${gameId}`).collection('actors').doc(`${actorUid}`).valueChanges();
	}

	addActor(gameId, actor) {
		return this.afs.doc(`games/${gameId}`).collection('actors').add({ ...actor });
	}

	updateActor(gameId, actorUid, data){
		return this.afs.doc(`games/${gameId}`).collection('actors').doc(`${actorUid}`).update(data);
	}

	deleteActor(gameId, actorUid) {
		return this.afs.doc(`games/${gameId}`).collection('actors').doc(`${actorUid}`).delete();
	}

	private handleError(error: any): Promise<any> {
		console.error('An error occurred', error); // for demo purposes only
		return Promise.reject(error.message || error);
    }
}
