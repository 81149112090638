import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import * as geofirestore from 'geofirestore';
import { GeoCollectionReference, GeoFirestore } from 'geofirestore';

import { Observable, BehaviorSubject } from 'rxjs';

import { map } from 'rxjs/operators';

import * as moment from 'moment-timezone';

import { City, Poi, Picture, Country } from '../classes/index';

@Injectable()
export class CityService {

	geofirestore: GeoFirestore;
	geocollection: GeoCollectionReference;

	cityBase;

	private _cities: BehaviorSubject<City[]>;
  	public cities: Observable<City[]>;

	constructor(private http: HttpClient, 
		private afs: AngularFirestore) {
			console.log('CityService initialization...');
			
			this._cities = <BehaviorSubject<[]>>new BehaviorSubject([]);
      		this.cities = this._cities.asObservable();

			// Create a Firestore reference
			const firestore = firebase.firestore();
			// Create a GeoFirestore reference
			this.geofirestore = geofirestore.initializeApp(firestore);

			// Create a GeoCollection reference
			this.geocollection = this.geofirestore.collection('cities');

			this.listCities()
			//.pipe(take(1))
			.subscribe(cities => {
				//console.log('Load cities:'+cities.length);
				this.cityBase = cities.sort((a, b) => {
					if (a.name['fr'] > b.name['fr']) return 1;
					else if (a.name['fr'] < b.name['fr']) return -1;
					else return 0;
				});
				this._cities.next(this.cityBase);
			});
	}

	API_KEY = 'AIzaSyCCDcQrsG6QWJrHWOyNH_2ksHxqLF2OhA4'
	URL_GET_TOKEN_BY_SIGN_IN = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithCustomToken?key=${this.API_KEY}`;
	
	getTokensBySigningIn = () => {
		return new Promise(async (resolve, reject) => {
		  const res = await fetch(this.URL_GET_TOKEN_BY_SIGN_IN, {
			method: "POST",
			headers: {
			  "Content-Type": "application/json",
			},
			body: JSON.stringify({
			  returnSecureToken: true
			}),
		  }).catch((err) => {
			reject(err);
		  });
		  resolve(res);
		});
	  };

	testGetTicket(){
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json')
		.set('Accept', 'application/json')
		.set('Authorization', 'Bearer d11280e63b4d07488070cc648fbacc3bd3a9359c');

		const structuredQuery = {
			"select": {
				"fields": [{"fieldPath":"__name__"}]
			},
			"from": [{
				"collectionId": "tickets",
				"allDescendants": false
			}],
		
			"where": {
				"compositeFilter": {
					"op": "AND",
					"filters": [{
						"fieldFilter": {
							"field": {
								"fieldPath": "email"
							},
							"op": "EQUAL",
							"value": {
								"stringValue": "nicolaurie1@gmail.com"
							}
						}
					},{
						"fieldFilter": {
							"field": {
								"fieldPath": "refNumber"
							},
							"op": "EQUAL",
							"value": {
								"stringValue": "COD-15917"
							}
						}
					}]
				}
			}
		};
  
		let url = 'https://firestore.googleapis.com/v1/projects/coddy-app/databases/(default)/documents:runQuery';
		return this.http.post(url,{structuredQuery: structuredQuery},{
		  headers: headers
		});
	}

	testGetViatorGygTicket(ref, date){
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json')
		.set('Accept', 'application/json')
		.set('Authorization', 'Bearer ya29.a0AfB_byAuhS2pWbYB6WvjCld3knI8H3EeSnXGZEXFTwsYs4HX2bhJq89GLQvyz0EQM6L9RHOEzYbc0wo2yxKejIiOclscHjtbOjzGh2PTyKQp8kwUNJEdg6XUKb1Z9LiKLA8Zyb2vOtZRUd0VIYqc1OBJ4CrgdDlrpagmLOLSAe5hi1Iz9_sWN4egJ4qC5n1RtMBVy0Nirn-pOEu-YT5SlIvXthFoPgPphvrIrg5ucHiILe6LJtAtayBwRoyzU_l0GHBguRIsdH79w2TZ2okRsAUwexftXv1vgwHmmNKstOBnouAIPsq3p8NYnYjPZaNKtLkC9Yquw2TIeYcOqsUfS7tvtwX9QK1ycN7icluQAMJM3QnM0abyM28mkqcOZAjBzEbsMmfQUbgilxAILVGuzIpjuaaI5waCgYKARASARESFQGOcNnCJgWSmsmLypDWyuar9nW5Yg0421');

		console.log('startDay:'+moment(date).startOf('day').valueOf());
		console.log('endDay:'+moment(date).endOf('day').valueOf());
		const structuredQuery = {
			"select": {
				"fields": [{"fieldPath":"__name__"}]
			},
			"from": [{
				"collectionId": "tickets",
				"allDescendants": false
			}],
		
			"where": {
				"compositeFilter": {
					"op": "AND",
					"filters": [{
						"fieldFilter": {
							"field": {
								"fieldPath": "refNumber"
							},
							"op": "EQUAL",
							"value": {
								"stringValue": ref
							}
						}
					},{
						"fieldFilter": {
							"field": {
								"fieldPath": "orderTime"
							},
							"op": "GREATER_THAN_OR_EQUAL",
							"value": {
								"doubleValue": moment(date).startOf('day').valueOf()
							}
						}
					},{
						"fieldFilter": {
							"field": {
								"fieldPath": "orderTime"
							},
							"op": "LESS_THAN_OR_EQUAL",
							"value": {
								"doubleValue": moment(date).endOf('day').valueOf()
							}
						}
					}]
				}
			}
		};
  
		let url = 'https://firestore.googleapis.com/v1/projects/coddy-app/databases/(default)/documents:runQuery';
		return this.http.post(url,{structuredQuery: structuredQuery},{
		  headers: headers
		});
	}

	testGetTicketTeams(ticketId){
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json')
		.set('Accept', 'application/json')
		.set('Authorization', 'Bearer ya29.a0AfB_byAuhS2pWbYB6WvjCld3knI8H3EeSnXGZEXFTwsYs4HX2bhJq89GLQvyz0EQM6L9RHOEzYbc0wo2yxKejIiOclscHjtbOjzGh2PTyKQp8kwUNJEdg6XUKb1Z9LiKLA8Zyb2vOtZRUd0VIYqc1OBJ4CrgdDlrpagmLOLSAe5hi1Iz9_sWN4egJ4qC5n1RtMBVy0Nirn-pOEu-YT5SlIvXthFoPgPphvrIrg5ucHiILe6LJtAtayBwRoyzU_l0GHBguRIsdH79w2TZ2okRsAUwexftXv1vgwHmmNKstOBnouAIPsq3p8NYnYjPZaNKtLkC9Yquw2TIeYcOqsUfS7tvtwX9QK1ycN7icluQAMJM3QnM0abyM28mkqcOZAjBzEbsMmfQUbgilxAILVGuzIpjuaaI5waCgYKARASARESFQGOcNnCJgWSmsmLypDWyuar9nW5Yg0421');

		const structuredQuery = {
			"from": [{
				"collectionId": "teams",
				"allDescendants": true
			}],
		
			"where": {
				"fieldFilter": {
					"field": {
						"fieldPath": "ticketId"
					},
					"op": "EQUAL",
					"value": {
						"stringValue": ticketId
					}
				}
			}
		};
  
		let url = 'https://firestore.googleapis.com/v1/projects/coddy-app/databases/(default)/documents:runQuery';
		return this.http.post(url,{structuredQuery: structuredQuery},{
		  headers: headers
		});
	}

	listCities(){
		return Observable.create((observer) => {
			this.geofirestore.collection('cities')
			.onSnapshot((querySnapshot) => {
				let cities = [];
				for (let doc of querySnapshot.docs) { 
					//console.log('received city:'+JSON.stringify(doc.data()));
					let data = new City();
					data.fromJSON(doc.data());
					data.id = doc.id;
					cities.push(data);
				}
				observer.next(cities);
			});
		});
	}

	listCitiesAround(latitude, longitude, radius, limit){
		// Create a GeoQuery based on a location
		//return this.geofirestore.collection('cities').near({ center: new firebase.firestore.GeoPoint(latitude, longitude), radius: 1000000 }).get();
		return this.geofirestore.collection('cities').where('published', '==', true)
		.near({ center: new firebase.firestore.GeoPoint(latitude, longitude), radius:radius })
		.limit(limit).get();
	}

	getCountry(){
		return this.afs.collection('countries').get()
		.pipe(map(countries => {
			return countries.docs.map(a => {
				const data:any = a.data();
				let m = new Country()
				m.fromJSON(data);
				m.id = a.id;
				return m;
			});
		}));
	}

	getCity(cityUid){
		return this.afs.collection('cities').doc(`${cityUid}`).valueChanges()
		.pipe(map(city => {
			console.log('getCity valueChanges:'+city);
			let obj = city as City;
			obj.id = cityUid;
			return obj;
		}));
	}

	getLocalCity(cityUid){
        //return this.afs.collection('themes').doc(`${themeUid}`).valueChanges();
		if(this.cityBase){
			return this.cityBase.filter(function( city ) {return city.id == cityUid})[0];
		}
		return null;
	}

	addCity(data) {
		//return this.afs.collection('cities').add({ ...city });
		if(data.latitude && data.longitude){
			data.coordinates = new firebase.firestore.GeoPoint(data.latitude, data.longitude);
		}
		return this.geocollection.add({ ...data });
	}

	updateCity(cityUid, data){
		//return this.afs.collection('cities').doc(`${cityUid}`).update(data);
		if(data.latitude && data.longitude){
			data.coordinates = new firebase.firestore.GeoPoint(data.latitude, data.longitude);
		}
		
		return this.geocollection.doc(`${cityUid}`).update({ ...data });
	}

	deleteCity(cityUid) {
		return this.afs.collection('cities').doc(`${cityUid}`).delete();
	}

	listCityPictures(cityId){
		return this.afs.collection('pictures', ref => ref.where('cityId', '==', cityId))
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Picture();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	getPicture(picUid){
        return this.afs.collection('pictures').doc(`${picUid}`).valueChanges();
	}

	addPicture(picture) {
		return this.afs.collection('pictures').add({ ...picture });
	}

	updatePicture(picUid, data){
		return this.afs.collection('pictures').doc(`${picUid}`).update(data);
	}

	deletePicture(picUid) {
		return this.afs.collection('pictures').doc(`${picUid}`).delete();
	}
}
