<nb-card class="modal-full modal-expand" [nbSpinner]="sending || translating" nbSpinnerSize="medium" nbSpinnerStatus="success">
  <nb-card-header>
    <div class="start inline-flex">
      <div class="">Ask ChatGPT</div>
    </div>
    <div class="end ml-4">
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <textarea rows="3" class="form-control" placeholder="Enter your prompt here"
          [(ngModel)]="prompt"></textarea>
    </div>
    
    <div class="row my-2" *ngIf="contentUrl">
      <div class="form-group w-full">
        <label for="input">Content will be extracted from url:</label>
        <input [(ngModel)]="contentUrl" id="input"
              class="form-control" placeholder="enter url here...">
      </div>
    </div>
    <div class="row my-2">
      <div class="col-auto">
        <div class="form-group">
          <label for="theme">Select answer language:</label>
          <nb-select class="ml-3" [(selected)]="lang" (selectedChange)="lang = $event">
            <nb-option *ngFor="let lang of languageService.languages" [value]="lang.name">{{ lang.name }}
            </nb-option>
          </nb-select>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-group">
          <label for="theme">Select GPT engine:</label>
          <nb-select class="ml-3" [(selected)]="engine">
            <nb-option value="gpt-3.5-turbo">gpt-3.5-turbo</nb-option>
            <nb-option value="gpt-4-0613">gpt-4-0613</nb-option>
            <nb-option value="gpt-4-1106-preview">gpt-4-1106-preview</nb-option>
          </nb-select>
        </div>
      </div>
      <div class="col-auto">
        <button nbButton class="ml-3" status="success" (click)="send()" [disabled]="sending">Send</button>
      </div>
    </div>

    <div class="row relative" *ngIf="answer">
      <textarea rows="6" class="form-control"
          [(ngModel)]="answer"></textarea>
    </div>

    <div class="row my-2" *ngIf="answer">
      <button nbButton status="danger" (click)="translateRes()">ChatGPT translate</button>
      <button nbButton status="danger" (click)="translateDeepl()" class="ml-2">Deepl translate</button>
    </div>

    <div class="row relative" *ngIf="translatedAnswer">
      <nb-card class="w-full">
        <nb-card-header>
          Translation
        </nb-card-header>
        <nb-card-body>
          <multilang-editor [model]="translatedAnswer" view="rows" editMode="true" [htmlMode]="true" [simple]="false"
            [languages]="languageService.languagesCodes">
          </multilang-editor>
        </nb-card-body>
      </nb-card>
    </div>

  </nb-card-body>
  <nb-card-footer>
    <div class="row">
      <div class="col-auto" *ngIf="answer">
        <button nbButton status="danger" (click)="validateAnswer(false)">Use {{lang}} translation</button>
      </div>
      <div class="col-auto" *ngIf="translatedAnswer">
        <button nbButton status="danger" (click)="validateAnswer(true)">Use all translations</button>
      </div>
      <div class="col-auto">
        <button nbButton status="danger" (click)="cancel()">Close</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
