import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { GameService, LanguageService } from '../../_services/index';
import { AuthService } from '../../auth/auth-service.service';
import { take } from 'rxjs';
import { User } from '../../classes';

import { NbDialogService } from '@nebular/theme';
import { ChatGPTModalComponent } from '../../components/ai/chatgpt-modal.component';

@Component({
  selector: 'app-main-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  
  playersMenu = [
    { title: 'All teams', link:'/pages/all-teams', icon:'people-outline'},
    { title: 'Live teams', link:'/pages/live', icon:'activity-outline'},
    { title: 'View gallery', link:'/pages/game/gallery', icon:'image-outline'},
    { title: 'Vouchers', link:'/pages/admin/vouchers', icon:'credit-card-outline'},
    { title: 'Tickets', link:'/pages/tickets', icon:'shopping-bag-outline'},
  ];
  settingsMenu =  [
    { title: 'Users', link:'/pages/admin/users', icon:'people-outline'},
    { title: 'Profile', link:'/pages/admin/users', icon:'people-outline'},
    { title: 'Log out', link:'/pages/admin/users', icon:'people-outline'},
  ]
  statsMenu = [
    { title: 'View stats', link:'/pages/stats/overview', icon:'activity-outline'},
    { title: 'Custom missions', link:'/pages/game/custom-missions', icon:'menu-2-outline'},
  ]
  gamesMenu = [
    { title: 'All games', link:'/pages/game/list', icon:'eye-outline'},
    { title: 'View templates', link:'/pages/template', icon:'menu-2-outline'},
    { title: 'View Cities', link:'/pages/city/list', icon:'eye-outline'},
    { title: 'View themes', link:'/pages/home', icon:'menu-outline'},
    { title: 'New theme', link:'/pages/theme/add', icon:'plus-outline'},
    { title: 'Open missions', link:'/pages/open-missions', icon:'menu-2-outline'},
  ]
  favoriteGames = [];
  games = [];
  user

  constructor(private router: Router,
    public authService: AuthService,
    private gameService: GameService,
    private dialogService: NbDialogService,
    private languageService: LanguageService
    ) {
    window.onclick = function(event) {
      var dropdown = document.getElementById("drop-nav");
      if (event.target.matches('.dropbtn')) {
        dropdown.style.display = "none";
      }
      if(event.target.matches('rect') &&event.target.parentElement.getAttribute('data-name') == 'menu'){
        dropdown.style.display = "block";
      }
      if(!event.target.matches('dropdown') && !event.target.matches('rect') && event.target.className != 'title'){
        if(dropdown.style.display == 'block') dropdown.style.display = "none";
      }
    }
  }

  ngOnInit() {
    this.getGames()
  }

  getGames(){
    this.gameService.listenAllGames()
    .pipe(take(1))
    .subscribe(games => {
      this.games = games
      this.getUserInfo()
    })
  }

  getUserInfo(){
    this.authService.getUser().subscribe((user:User) => {
      if(user){
        this.user = user
        if(this.user.favoriteGames && this.user.favoriteGames?.length>0) this.setFavorite(this.user.favoriteGames)
      }
    });
  }

  setFavorite(favoriteGames){
    this.favoriteGames = []
    for (let i = 0; i < favoriteGames.length; i++) {
      const gameId = favoriteGames[i];
      this.favoriteGames.push({ title: this.getNameGame(gameId), link:`/pages/game/${gameId}/overview`, icon:'eye-outline'})
    }
  }

  getNameGame(gameId){
    let result = this.games.find(game => game.id == gameId)
    if(result) return result.name
  }

  getPage(val){
    this.router.navigate([''+val+'']);
  }

  @HostListener('document:keydown.control.g')
  askChatGPT(){
    this.dialogService.open(ChatGPTModalComponent, { 
      context: {
      }, closeOnBackdropClick: false
    })
    .onClose.subscribe(async res => {
      console.log('Dialog returns:'+res);
      if(res){
      }
    });
  }
}
