import Serializable from './serializable';

export class GameTemplate extends Serializable {
	id: string;

	themeId: string;
	episodeId: string;

	name: {} = {};
	description: {} = {};
	minimumNativeVersion: string;

	title: {} = {};
	articleText: {} = {};
	highlightPicture: string;
	highlightText: {} = {};

	availableLanguages: [];
	workingLanguages: [];
	defaultLang: string;

	locationMode: string; //GEO/VIRTUAL/NONE
	mapTheme: {
		virtualMapUrl: string; //https://coddy-app.firebaseapp.com/assets/map/pirates
		minZoom: number;
		maxZoom: number;
	}

	alwaysVisible: boolean;
	
	timeLimited: boolean;
	timeLimitedMinutes: number;
	timerStartMission: string;
	timerStopMission: string;
	stopWhenOver: boolean;
	timerFallbackMission: string;

	isProgressActive: boolean;
	progressAverageSteps: number;

	startTime: number;
	duration: number;
	endTime: number;
	timezone: string;

	hasRoles: boolean;
	hasCharacters: boolean;
	creationDate: number;

	pictureUrl: string;
	thumbFileName: string;
	thumbFileURL: string;
	pictureLastUpdate: number;

	documents: {} = {en:{}, fr:{}, nl:{}};
	instructions: {} = {en:"", fr:"", nl:""};
	hasGameOverUrl: boolean;
	gameOverUrl: string;

	averageMissionCount: number;
	missions;
}
