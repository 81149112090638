import Serializable from './serializable';

export class City extends Serializable {

	id: string;
	
	orderNum: number;
	code: string;
	countryId: string;

	published:boolean;

	name: {} = {};
	description: {} = {};
	
	pictureUrl: string;
	thumbFileName: string;
	thumbFileURL: string;

	importedPoiCount: number;
    activePoiCount: number;
    dismissedPoiCount: number;
}
