import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { Event } from '../classes/index';

import { map } from 'rxjs/operators';

@Injectable()
export class EventService {

	constructor(private http: HttpClient, 
		private afs: AngularFirestore
	) {
	}

	getEvents(gameId) {
		return this.afs.doc(`games/${gameId}`).collection('events').snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received event:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as Event;
				let parsedEvent = new Event();
				parsedEvent.fromJSON(data);
				parsedEvent.id = a.payload.doc.id;
				return parsedEvent;
			});
		}));
	}

	getScoreableEvents(gameId){
		return this.afs.doc(`games/${gameId}`).collection('events', ref => ref.where('scoreable', '==', true)).snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received event:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as Event;
				let parsedEvent = new Event();
				parsedEvent.fromJSON(data);
				parsedEvent.id = a.payload.doc.id;
				return parsedEvent;
			});
		}));
	}

	getTeamEvents(gameId, teamId) {
		if(teamId == '-1'){
			return this.getEvents(gameId);
		}
		return this.afs.doc(`games/${gameId}`).collection('events', ref => ref.where('teamId', '==', teamId)).snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received event:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as Event;
				let parsedEvent = new Event();
				parsedEvent.fromJSON(data);
				parsedEvent.id = a.payload.doc.id;
				return parsedEvent;
			});
		}));
	}

	addEvent(gameId, event) {
		
		//game.creationDate = firebase.firestore.FieldValue.serverTimestamp()
		/*log.position = this.geolocationService.myposition
		.subscribe( pos => {
			if(pos){
				log.position = pos;
			}
		});*/
		return this.afs.doc(`games/${gameId}`).collection('events').add({ ...event });
	}

	updateEvent(gameId, eventUid, data){
		return this.afs.doc(`games/${gameId}`).collection('events').doc(`${eventUid}`).update(data);
	}
}
