import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from './auth/auth-guard.service';
import { RedirectGuard } from './_guards/redirect.guard';

const routes: Routes = [
  {
    path: 'pages', 
    canActivate: [AuthGuard], 
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  { 
    path: 'i/:id', 
    //canActivate: [RedirectGuard], 
    //data: {redirect: '/public/instructions/:id'}
    redirectTo: '/public/instructions/:id' 
  },
  { 
    path: 'j/:id/:teamId/:lang', 
    //canActivate: [RedirectGuard], 
    //data: {redirect: '/public/:id/journal/:teamId'}
    redirectTo: '/public/:id/journal/:teamId/:lang' 
  },
  { 
    path: 'j/:id/:teamId', 
    redirectTo: '/public/:id/journal/:teamId' 
  },
  {
    path: 'public', 
    canActivate: [], 
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
    useHash: false,
    relativeLinkResolution: 'legacy',
    paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
