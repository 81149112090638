import Serializable from './serializable';

export class Picture extends Serializable {

	id: string;

	userId: string;
	cityId: string;
	poiId: string;

	missionId: string;

	name: {} = {};
	description: {} = {};

	takenDate: number;
	uploadDate: number;
}
