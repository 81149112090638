import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { take, map } from 'rxjs/operators';

import { Team, Group, TeamGroup, Player, Event, Mission } from '../classes/index';

import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable()
export class GroupService {

	constructor(
		private http: HttpClient,
		private afs: AngularFirestore
	) { }

	listTeamGroups(gameId){
		return this.afs.doc(`games/${gameId}`).collection('groups')
		.valueChanges({ idField: 'id' })
		.pipe(map(collection => {
			return collection.map(data => {
				let object = new Group();
				object.fromJSON(data);
				return object;
			});
		}));
	}

	getGroup(gameId, groupId){
		return this.afs.doc(`games/${gameId}/groups/${groupId}`).get()
		.pipe(map(template => {
			if(template && template.data()){
				let obj = template.data() as Group;
				//console.log('Template found:'+templateUid+': '+template.data());
				obj.id = groupId;
				return obj;
			}
			return null;
		}));
	}

	addGroup(gameId, data) {
		return this.afs.doc(`games/${gameId}`).collection('groups').add({...data});
	}

	updateGroup(gameId, groupId, data){
		return this.afs.doc(`games/${gameId}/groups/${groupId}`).update(data);
	}

	async sendMessage(gameId, groupId, conversationId, chatMessage, teamId){
		await this.afs.doc(`games/${gameId}/groups/${groupId}/chats/${conversationId}`)
		.collection('messages').add(chatMessage);
		let conversationData = {
			members: ['admin', teamId],
			lastMessage: chatMessage.text,
			lastMessageFrom: chatMessage.from,
			lastMessageTime: chatMessage.time
		};
		await this.afs.doc(`games/${gameId}/groups/${groupId}/chats/${conversationId}`).set(conversationData, {merge:true});
	}

	createGroupRest(createGroupStruct){
		let headers = new HttpHeaders();
		headers = headers.set('Content-Type', 'application/json')
		.set('Accept', 'application/json')
		.set('Authorization', 'Bearer b640HXSFuVd48cdqpLxJTbLxJTbBMYnPAem9QyZNiLTliMmQtN2FkO');
  
		let url = 'https://us-central1-coddy-app.cloudfunctions.net/createGroup';
		return this.http.post(url,createGroupStruct,{
		  headers: headers
		});
	}

	deleteGroup(gameId, groupUid) {
		return this.afs.doc(`games/${gameId}`).collection('groups').doc(`${groupUid}`).delete();
	}
}
