import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NbMenuModule, 
  NbDialogModule, 
  NbTabsetModule,
  NbAccordionModule,
  NbCardModule,
  NbSelectModule,
  NbToggleModule,
  NbIconModule,
  NbButtonModule,
  NbContextMenuModule,
  NbCheckboxModule,
  NbAlertModule,
  NbSpinnerModule,
  NbListModule,
  NbStepperModule,
  NbRadioModule } from '@nebular/theme';
import { TableModule } from 'ngx-easy-table';
import { ThemeModule } from '../@theme/theme.module';

import { QRCodeModule } from 'angularx-qrcode';

import { QuillModule } from 'ngx-quill';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { DialogComponent } from './dialog/dialog.component';

import { AgmCoreModule } from '@agm/core';

import { TableComponent } from './table/table.component';
import { SearchComponent } from './search/search.component';
import { FilterSearchComponent } from './filter-search/filter-search.component';
import { QuillEditorComponent } from './editor/quill/quill.component';
import { MultilangEditorComponent } from '../components/editor/multilang-editor.component';
import { MultilangEditModalComponent } from '../components/editor/multilang-edit-modal.component';

import { BlockEditorComponent } from '../components/editor/block-editor/block-editor.component';
import { BlockEditModalComponent } from '../components/editor/block-editor/block-edit-modal.component';
import { EditorDialogComponent } from '../components/editor/block-editor/components/editor-dialog.component';
import { EditorMediaComponent } from '../components/editor/block-editor/components/editor-media.component';
import { BlockViewerComponent } from '../components/editor/block-editor/viewer/block-viewer.component';
import { DialogViewerComponent } from '../components/editor/block-editor/viewer/dialog-viewer.component';
import { MediaViewerComponent } from '../components/editor/block-editor/viewer/media-viewer.component';
import { MediaLibComponent } from '../components/medias/library/media-lib.component';

import { MediaSelectorComponent } from '../components/medias/media-selector.component';
import { SelectMediaModalComponent } from '../components/medias/library/select-media-modal.component';

import { ImportMissionTemplateComponent } from '../components/import-mission-template/import-mission-template.component';
import { ImportOpenMissionTemplateComponent } from '../components/import-open-mission/import-open-mission.component';
//import { GameMenuComponent } from '../pages/game/menu/menu.component';
//import { TemplateMenuComponent } from '../pages/template/menu/template-menu.component';
import { ConfirmModalComponent } from './modals/confirm-modal.component';
import { AlertModalComponent } from './modals/alert-modal.component';
import { TemplateUpdateModalComponent } from './template-update/template-update-modal.component';
import { LangDiffComponent } from '../components/template-update/lang-diff/lang-diff.component';

import { EditMissionComponent } from '../components/edit-mission/edit-mission.component';
import { EditMissionParamsComponent } from '../components/edit-mission-param/edit-mission-param.component';
import { EditMissionReferencesComponent } from '../components/edit-mission-references/edit-mission-references.component';
import { ParameterMissionComponent } from '../components/parameter-mission/parameter-mission.component';
import { MissionPreviewComponent } from '../components/mission-preview/mission-preview.component';

import { ObjectEditModalComponent } from '../components/edit-object/object-edit-modal.component';
import { EditObjectsComponent } from '../components/objects/edit-objects.component';
import { EditRolesComponent } from '../components/roles/edit-roles.component';
import { RoleEditModalComponent } from '../components/roles/edit/role-edit-modal.component';
import { EditCharactersComponent } from '../components/characters/edit-characters.component';
import { CharacterEditModalComponent } from '../components/characters/edit/character-edit-modal.component';
import { ObjectChangesModalComponent } from '../components/object-changes/object-changes-modal.component';
import { CharacterChangesModalComponent } from '../components/characters/changes/character-changes-modal.component';
import { RoleChangesModalComponent } from '../components/roles/changes/role-changes-modal.component';
import { DropdownComponent } from '../components/dropdown/dropdown.component';

import { searchBoxComponent } from './search-box/search-box.component';
import { paginationComponent } from './pagination/pagination.component';

import { ChatGPTModalComponent } from './ai/chatgpt-modal.component';



@NgModule({
  imports: [
    TableModule,
    ThemeModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    RouterModule,
    QuillModule,
    CKEditorModule,
    QRCodeModule,
    NbDialogModule.forChild(),
    NbTabsetModule,
    NbAccordionModule,
    NbMenuModule,
    NbCardModule,
    NbSelectModule,
    NbToggleModule,
    NbIconModule,
    NbButtonModule,
    NbContextMenuModule,
    NbCheckboxModule,
    NbAlertModule,
    NbSpinnerModule,
    NbListModule,
    DragDropModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCzC1yvO6W4Xw-C2ZUjEkjQ89kzqmxVfxo',//AIzaSyBU95BQZbwJcn6bbSKbQSysBx_FB6iqlP4
      libraries: ['places']
    }),
    NbStepperModule,
    NbRadioModule
  ],
  declarations: [
    //TemplateMenuComponent,
    //GameMenuComponent,
    DialogComponent,
    ConfirmModalComponent,
    AlertModalComponent,
    TemplateUpdateModalComponent,
    LangDiffComponent,
    EditMissionComponent,
    ParameterMissionComponent,
    EditMissionParamsComponent,
    EditMissionReferencesComponent,
    MissionPreviewComponent,
    ObjectEditModalComponent,
    EditObjectsComponent,
    EditRolesComponent,
    RoleEditModalComponent,
    EditCharactersComponent,
    CharacterEditModalComponent,
    ObjectChangesModalComponent,
    CharacterChangesModalComponent,
    RoleChangesModalComponent,
    QuillEditorComponent,
    MultilangEditorComponent,
    MultilangEditModalComponent,
    BlockEditorComponent,
    BlockEditModalComponent,
    EditorDialogComponent,
    EditorMediaComponent,
    BlockViewerComponent,
    DialogViewerComponent,
    MediaLibComponent,
    MediaViewerComponent,
    MediaSelectorComponent,
    SelectMediaModalComponent,
    ImportMissionTemplateComponent,
    SearchComponent,
    TableComponent,
    searchBoxComponent,
    paginationComponent,
    FilterSearchComponent,
    DropdownComponent,
    ImportOpenMissionTemplateComponent,
    ChatGPTModalComponent
  ],
  exports: [
    DialogComponent,
    EditMissionComponent,
    EditMissionParamsComponent,
    EditMissionReferencesComponent,
    ParameterMissionComponent,
    MissionPreviewComponent,
    MultilangEditorComponent,
    BlockEditorComponent,
    EditorDialogComponent,
    EditorMediaComponent,
    BlockViewerComponent,
    DialogViewerComponent,
    MediaLibComponent,
    MediaViewerComponent,
    MediaSelectorComponent,
    LangDiffComponent,
    SearchComponent,
    TableComponent,
    searchBoxComponent,
    paginationComponent,
    DropdownComponent,
    FilterSearchComponent
  ],
  entryComponents: [
    ConfirmModalComponent,
    AlertModalComponent,
    TemplateUpdateModalComponent,
    ObjectEditModalComponent,
    EditObjectsComponent,
    EditRolesComponent,
    RoleEditModalComponent,
    EditCharactersComponent,
    CharacterEditModalComponent,
    MultilangEditModalComponent,
    BlockEditModalComponent,
    SelectMediaModalComponent,
    ImportMissionTemplateComponent,
    ImportOpenMissionTemplateComponent,
    ChatGPTModalComponent
  ]
})
export class ComponentsModule {
}
