import Serializable from './serializable';

export class Language extends Serializable {

	id: string;
	
	code: string;

	name: {} = {};
}
