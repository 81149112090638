import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { environment } from '../../environments/environment';

import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class StatService {

	constructor(private http: HttpClient, 
		private afs: AngularFirestore) {
			console.log('StatService initialization...');
			
	}

	listDailyStats(){
		return this.afs.collection('statistics/daily/data', ref => ref.orderBy('startTime', 'desc').limit(30))
		.valueChanges({ idField: 'id' });		
	}

	listDailyStatsFrom(from){
		console.log('listDailyStatsFrom:'+from);
		return this.afs.collection('statistics/daily/data', ref => ref.where('startTime','>=',from).orderBy('startTime', 'desc'))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as any;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}));//.valueChanges({ idField: 'id' });		
	}

	listStatsFromTo(dateTimeDebut, dateTimeFin){
		var start = moment(dateTimeDebut).startOf('day').valueOf();
		var end = moment(dateTimeFin).endOf('day').valueOf();
		return this.afs.collection('statistics/daily/data', ref => ref.where("startTime", ">=", start).where("startTime", "<=", end).orderBy('startTime', 'asc'))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as any;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}));	
	}
	listStatsMonthlyFromTo(currentYear){
		return this.afs.collection('statistics/monthly/data', ref => ref.where("year", "==", currentYear).orderBy('month', 'asc'))
		.get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
			  const item = a.data() as any;
			  item.id = a.id;
			  data.push(item);
			});
			return data;
		}));	
	}
}
